<template>
  <v-card elevation="0">
    <v-container>
      <!-- Step 1 -->
      <v-form ref="form1" v-if="!confirmChoice">
        <p class="subheading">
          A seguito di verifiche effettuate presso il nostro magazzino vi
          confermiamo che:
        </p>
        <v-radio-group v-model="choice">
          <v-radio
            value="1"
            label="Il numero colli riportato su DDT è corretto e richiediamo il ritiro del collo/dei colli mancante presso la nostra sede."
          ></v-radio>
          <v-radio
            value="2"
            label="Il numero dei colli riportato su DDT è errato e provvediamo ad inoltrare DDT corretta."
          ></v-radio>
          <v-radio
            value="3"
            label="Il numero colli riportato su DDT è corretto e confermiamo di aver affidato tutti i colli."
          ></v-radio>
        </v-radio-group>
        <v-layout row class="justify-end">
          <v-btn :disabled="!choice" color="primary" @click="checkChoice()">
            Conferma
          </v-btn>
        </v-layout>
      </v-form>
      <v-layout row wrap align-center class="mb-7">
        <v-divider v-if="confirmChoice"></v-divider>
      </v-layout>

      <!-- Step 2 - Case 1 -->
      <v-form
        ref="form2"
        v-model="case1"
        v-if="choice == '1' && confirmChoice"
        class="mt-3"
      >
        <v-layout row wrap align-center>
          <v-flex xs12>
            <p class="subheading font-weight-bold">
              Grazie per aver fornito le informazioni
            </p>
          </v-flex>
          <v-flex xs12 sm6 md4>
            <span class="subheading">
              Inserire data richiesta per ritiro collo mancante
            </span>
          </v-flex>
          <v-flex xs12 sm3 md3>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              lazy
              transition="scale-transition"
              offset-y
              full-width
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                show-current
                :min="currentDate"
                :max="maxDate"
                @input="menu = false"
                color="primary"
                locale="it-IT"
                first-day-of-week="1"
              ></v-date-picker>
            </v-menu>
          </v-flex>
          <v-flex xs12 sm3 md5></v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 sm6 md3>
            <v-text-field
              label="Numero colli"
              :value="colliDDT - colliMag"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md9></v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 sm6 md6>
            <v-text-field
              label="Inserire peso (kg)"
              v-model="peso"
              @keypress="isFloat($event)"
              :rules="[pesoRule(), (v) => !!v || 'Campo obbligatorio']"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md6></v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 sm6 md6>
            <v-text-field
              maxlength="30"
              label="Persona di riferimento"
              v-model="riferimento"
              :rules="[(v) => !!v || 'Campo obbligatorio']"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md6></v-flex>
        </v-layout>
        <v-layout row>
          <v-flex xs12 sm3>
            <v-checkbox
              v-model="soloMattino"
              :label="$t('key394')"
            ></v-checkbox>
          </v-flex>
          <v-flex xs12 sm3>
            <v-checkbox
              v-model="soloPomeriggio"
              :label="$t('key395')"
            ></v-checkbox>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex
            xs12
            sm2
            v-if="soloMattino || (!soloMattino && !soloPomeriggio)"
          >
            <v-text-field
              :background-color="MDMColore"
              ref="mdmText"
              outlined
              v-model="MDM"
              :label="$t('key085')"
              prepend-icon="mdi-clock-time-four-outlined"
              @keypress="isIntegerDate($event)"
              @blur="CheckMDM"
              browser-autocomplete="null"
              maxlength="5"
            ></v-text-field>
          </v-flex>
          <v-flex
            xs12
            sm2
            v-if="soloMattino || (!soloMattino && !soloPomeriggio)"
          >
            <v-text-field
              :background-color="MAMColore"
              ref="mamText"
              outlined
              v-model="MAM"
              :label="$t('key086')"
              prepend-icon="mdi-clock-time-four-outlined"
              @keypress="isIntegerDate($event)"
              @blur="CheckMAM"
              browser-autocomplete="null"
              maxlength="5"
            ></v-text-field>
          </v-flex>
          <v-flex
            xs12
            sm2
            v-if="soloPomeriggio || (!soloMattino && !soloPomeriggio)"
          >
            <v-text-field
              :background-color="PDPColore"
              ref="pdpText"
              outlined
              v-model="PDP"
              :label="$t('key087')"
              prepend-icon="mdi-clock-time-four-outlined"
              @keypress="isIntegerDate($event)"
              @blur="CheckPDP"
              @focus="CheckMattino"
              browser-autocomplete="null"
              maxlength="5"
            ></v-text-field>
          </v-flex>
          <v-flex
            xs12
            sm2
            v-if="soloPomeriggio || (!soloMattino && !soloPomeriggio)"
          >
            <v-text-field
              :background-color="PAPColore"
              ref="papText"
              outlined
              v-model="PAP"
              :label="$t('key088')"
              prepend-icon="mdi-clock-time-four-outlined"
              @keypress="isIntegerDate($event)"
              @blur="CheckPAP"
              browser-autocomplete="null"
              maxlength="5"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row class="justify-end">
          <v-btn
            color="#A0A4A7"
            style="color: white"
            @click="goBack()"
            class="mr-2"
          >
            Torna indietro
          </v-btn>
          <v-btn :disabled="!case1" color="primary" @click="sendData()">
            Invia
          </v-btn>
        </v-layout>
      </v-form>

      <!-- Step 2 - Case 2 -->
      <v-form ref="form2" v-if="choice == '2' && confirmChoice" class="mt-3">
        <v-layout row wrap align-center>
          <v-flex xs12>
            <p class="subheading font-weight-bold">
              Grazie per aver fornito le informazioni
            </p>
          </v-flex>
          <v-radio-group v-model="choiceDDT">
            <v-radio
              value="1"
              label="Allego DDT corretta ora (link allega)."
            ></v-radio>
            <span v-if="uploadName != ''" class="d-inline">{{
              uploadName
            }}</span>
            <!-- <upload-button
              large
              style="box-shadow: none; color: white"
              lazy
              title="CARICA DDT"
              noTitleUpdate
              @file-update="fileSelectedFunc"
            ></upload-button> -->
            <div>
              <v-btn
                color="primary"
                dark
                large
                depressed
                @click="$refs.uploader.click()"
              >
                CARICA DDT
              </v-btn>
              <input
                ref="uploader"
                class="d-none"
                type="file"
                @change="fileSelectedFunc"
                accept=".pdf,.jpeg,.png"
              />
            </div>

            <v-radio
              class="mt-2"
              value="2"
              label="Invierò DDT corretta rispondendo alla mail di giacenza (la spedizione non verrà inoltrata fino a quando la DDT corretta non verrà inviata)."
            ></v-radio>
          </v-radio-group>
        </v-layout>
        <v-layout row class="justify-end">
          <v-btn
            color="#A0A4A7"
            style="color: white"
            @click="goBack()"
            class="mr-2"
          >
            Torna indietro
          </v-btn>
          <v-btn
            :disabled="(uploaded == '' && choiceDDT == '1') || choice == '0'"
            color="primary"
            @click="sendData()"
          >
            Invia
          </v-btn>
        </v-layout>
      </v-form>

      <!-- Dialogs -->
      <!-- Loading -->
      <v-layout justify-center>
        <v-dialog v-model="overlay" persistent max-width="600">
          <v-card>
            <v-card-text class="pt-5">
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- Success -->
      <v-layout justify-center>
        <v-dialog v-model="successDialog" persistent max-width="530">
          <v-card>
            <v-card-title class="headline">Grazie</v-card-title>
            <v-card-text v-if="choice == '1'" class="subheading"
              >Provvederemo al ritiro come da richiesta e quindi procederemo
              allo svincolo della giacenza e all’inoltro della spedizione
              corretta.
            </v-card-text>
            <v-card-text
              v-if="choice == '2' && choiceDDT == '1'"
              class="subheading"
              >La giacenza è stata correttamente svincolata. Procederemo
              all’inoltro della spedizione con la DDT che è stata allegata.
            </v-card-text>
            <v-card-text
              v-if="choice == '2' && choiceDDT == '2'"
              class="subheading"
              >Restiamo in attesa di ricevere la DDT corretta al fine di
              svincolare definitivamente la giacenza e predisporre l’invio della
              spedizione.
            </v-card-text>
            <v-card-text v-if="choice == '3'" class="subheading"
              >Provvederemo ad ulteriori ricerche: in caso di esiti negativi
              apriremo una pratica per mancanza. In questo caso riceverà
              ulteriori informazioni.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed @click="close()">Esci</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- Upload file error -->
      <v-layout justify-center>
        <v-dialog v-model="uploadError" persistent max-width="530">
          <v-card>
            <v-card-title class="headline">Attenzione</v-card-title>
            <v-card-text class="subheading"
              >Possono essere caricati solo file inf formato pdf,jpeg o png.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" depressed @click="uploadError = false"
                >Esci</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- Error -->
      <v-layout justify-center>
        <v-dialog v-model="generalError" persistent max-width="530">
          <v-card>
            <v-card-title class="headline">Attenzione</v-card-title>
            <v-card-text class="subheading"
              >Il sistema non riesce a risolvere la chiamata ai servizi,
              contattare l'assistenza.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                depressed
                @click="generalError = false"
                >Esci</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- Errore orari prima di invio -->
      <v-layout justify-center>
        <v-dialog v-model="orariError" persistent max-width="530">
          <v-card>
            <v-card-title class="headline">Attenzione</v-card-title>
            <v-card-text v-html="Errore" class="subheading"></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                depressed
                @click="orariError = false"
                >Esci</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- Errori orario di Stefano -->
      <v-layout justify-center>
        <v-dialog v-model="DialogErroreOrarioMAM" persistent max-width="600">
          <v-card>
            <v-card-title class="headline">{{ $t("key446") }}</v-card-title>
            <v-card-text v-html="ErroreOrario"></v-card-text>
            <v-card-text v-html="ErroreOrarioMattino"></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                text
                @click="
                  DialogErroreOrarioMAM = false;
                  DialogErroreOrario = false;
                  DialogErroreOrarioPDP = false;
                  DialogErroreOrarioPAP = false;
                  focusNextTick($refs.mamText);
                "
                >{{ $t("key445") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-layout justify-center>
        <v-dialog v-model="DialogErroreOrarioPDP" persistent max-width="600">
          <v-card>
            <v-card-title class="headline">{{ $t("key447") }}</v-card-title>
            <v-card-text v-html="ErroreOrario"></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                text
                @click="
                  DialogErroreOrarioMAM = false;
                  DialogErroreOrario = false;
                  DialogErroreOrarioPDP = false;
                  DialogErroreOrarioPAP = false;
                  focusNextTick($refs.pdpText);
                "
                >{{ $t("key445") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-layout justify-center>
        <v-dialog v-model="DialogErroreOrarioPAP" persistent max-width="600">
          <v-card>
            <v-card-title class="headline">{{ $t("key448") }}</v-card-title>
            <v-card-text v-html="ErroreOrario"></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                text
                @click="
                  DialogErroreOrarioMAM = false;
                  DialogErroreOrario = false;
                  DialogErroreOrarioPDP = false;
                  DialogErroreOrarioPAP = false;
                  focusNextTick($refs.papText);
                "
                >{{ $t("key445") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-layout justify-center>
        <v-dialog v-model="DialogErroreOrario" persistent max-width="600">
          <v-card>
            <v-card-title class="headline">{{ $t("key449") }}</v-card-title>
            <v-card-text v-html="ErroreOrario"></v-card-text>
            <v-card-text v-html="ErroreOrarioMattino"></v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                color="primary"
                text
                @click="
                  DialogErroreOrarioMAM = false;
                  DialogErroreOrario = false;
                  DialogErroreOrarioPDP = false;
                  DialogErroreOrarioPAP = false;
                  focusNextTick($refs.mdmText);
                "
                >{{ $t("key445") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
      <!-- Nessuna scelta fatta -->
      <v-layout justify-center>
        <v-dialog v-model="choiceError" persistent max-width="530">
          <v-card>
            <v-card-title class="headline">Attenzione</v-card-title>
            <v-card-text class="subheading"
              >E' obbligatorio selezionare almeno una voce per procedere allo
              svincolo della giacenza.
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                depressed
                @click="choiceError = false"
                >Esci</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "GFFColliMagDDT",
  props: ["giacenza", "colliMag", "colliDDT"],
  data: () => ({
    ErroreOrarioMattino: "",
    ErroreOrario: "",
    orariError: false,
    Errore: "",
    DialogErroreOrarioMAM: false,
    DialogErroreOrario: false,
    DialogErroreOrarioPDP: false,
    DialogErroreOrarioPAP: false,
    soloMattino: false,
    soloPomeriggio: false,
    MDMColore: "",
    MAMColore: "",
    PDPColore: "",
    PAPColore: "",
    MDM: "08:00",
    MAM: "13:00",
    PDP: "13:30",
    PAP: "18:00",
    noteOrari: "",
    overlay: false,
    choice: "0",
    case1: true,
    confirmChoice: false,
    menu: false,
    currentDate: new Date().toISOString().substr(0, 10),
    date: new Date().toISOString().substr(0, 10),
    maxDate: "",
    colli: "",
    peso: "",
    riferimento: "",
    successDialog: false,
    choiceDDT: "1",
    estensione: "",
    uploaded: "",
    uploadName: "",
    uploadError: false,
    generalError: false,
    choiceError: false,
  }),
  mounted() {
    this.maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1))
      .toISOString()
      .substr(0, 10);
  },
  methods: {
    focusNextTick(el) {
      this.$nextTick(() => {
        el.focus();
      });
    },
    CheckMDM() {
      this.ErroreOrario = "";
      var erroreMDM = "";
      var vuoto = false;
      var vuotoMAM = false;
      if (this.MDM === null || this.MDM === "" || this.MDM === "00:00") {
        erroreMDM += "";
        vuoto = true;
      }
      if (this.MAM === "" || this.MAM === "00:00") {
        vuotoMAM = true;
      }
      var regexNum = /[^0-99]/g;
      var resultMDM = this.MDM.replaceAll(":", "").match(regexNum);
      if (resultMDM != null) {
        erroreMDM += this.$t("key585");
      }
      if (
        this.MDM.trim().length != 5 ||
        this.MDM.trim().replaceAll(":", "").length != 4
      )
        erroreMDM += this.$t("key471");
      else {
        if (!this.MDM.includes(":")) erroreMDM += this.$t("key471");
        if (this.MDM[2] != ":") erroreMDM += this.$t("key471");
        if (this.MDM.substring(0, 2) > this.MAM.substring(0, 2))
          erroreMDM += this.$t("key473");
        if (
          Number(this.MDM.substring(0, 2)) > 13 ||
          (Number(this.MDM.substring(0, 2)) === 13 &&
            Number(this.MDM.substring(3, 5)) > 0)
        )
          erroreMDM += this.$t("key474");
        if (
          Number(this.MDM.substring(0, 2)) < 6 ||
          (Number(this.MDM.substring(0, 2)) === 6 &&
            Number(this.MDM.substring(3, 5)) < 30)
        )
          erroreMDM += this.$t("key475");
        if (
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        )
          erroreMDM += this.$t("key473");
        if (this.MDM == this.MAM) erroreMDM += this.$t("key477");
        if (Number(this.MDM.substring(3, 5)) > 59)
          erroreMDM += this.$t("key471");
      }
      if (erroreMDM != "" && vuoto === false && vuotoMAM === false) {
        this.DialogErroreOrario = true;
        this.ErroreOrario = erroreMDM;
      }
      if (vuoto) {
        this.MDM = "00:00";
      }
    },
    CheckMattino() {
      if (!this.soloMattino && !this.soloPomeriggio) {
        this.ErroreOrarioMattino = "";
        var erroreMAM = "";

        if (
          (this.MAM === null || this.MAM === "" || this.MAM === "00:00") &&
          this.MDM !== "" &&
          this.MDM !== "00:00"
        ) {
          erroreMAM += this.$t("key479");
          this.DialogErroreOrarioMAM = true;
          this.ErroreOrarioMattino = erroreMAM;
        } else {
          if (
            this.MAM !== "" &&
            this.MAM !== "00:00" &&
            (this.MDM === "00:00" || this.MDM === "")
          ) {
            erroreMAM += this.$t("key480");
            this.DialogErroreOrario = true;
            this.ErroreOrarioMattino = erroreMAM;
          }
        }
      }
    },
    CheckMAM() {
      this.ErroreOrario = "";
      var erroreMAM = "";
      var vuoto = false;
      var vuotoMDM = false;

      if (this.MAM === null || this.MAM === "" || this.MAM === "00:00") {
        // erroreMAM += "- Il campo orario chiusura della mattina deve essere valorizzato se valorizzato l'orario di apertura<br>"
        vuoto = true;
        this.MAM = "00:00";
      }
      if (this.MDM === "" || this.MDM === "00:00") {
        vuotoMDM = true;
      }
      var regexNum = /[^0-99]/g;
      var resultMAM = this.MAM.replaceAll(":", "").match(regexNum);
      if (resultMAM != null) {
        erroreMAM += this.$t("key585");
      }
      if (
        this.MAM.trim().length != 5 ||
        this.MAM.trim().replaceAll(":", "").length != 4
      )
        erroreMAM += this.$t("key482");
      else {
        if (!this.MAM.includes(":")) erroreMAM += this.$t("key482");
        if (
          Number(this.MAM.substring(0, 2)) > 13 ||
          (Number(this.MAM.substring(0, 2)) === 13 &&
            Number(this.MAM.substring(3, 5)) > 0)
        )
          erroreMAM += this.$t("key474");
        if (
          Number(this.MAM.substring(0, 2)) < 6 ||
          (Number(this.MAM.substring(0, 2)) === 6 &&
            Number(this.MAM.substring(3, 5)) < 30)
        )
          erroreMAM += this.$t("key475");
        if (this.MDM.substring(0, 2) > this.MAM.substring(0, 2))
          erroreMAM += this.$t("key485");
        if (
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        )
          erroreMAM += this.$t("key485");
        if (this.MDM == this.MAM) erroreMAM += this.$t("key487");
        if (Number(this.MAM.substring(3, 5)) > 59)
          erroreMAM += this.$t("key488");
      }
      if (erroreMAM != "" && vuoto === false && vuotoMDM === false) {
        this.DialogErroreOrarioMAM = true;
        this.ErroreOrario = erroreMAM;
      }
    },
    CheckPDP() {
      this.ErroreOrario = "";
      var errorePDP = "";
      var vuoto = false;
      if (this.PDP === null || this.PDP === "" || this.PDP === "00:00") {
        vuoto = true;
        this.PDP = "00:00";
      }
      if (this.PDP == null || this.PDP == "") errorePDP += this.$t("key489");
      var regexNum = /[^0-99]/g;
      var resultPDP = this.PDP.replaceAll(":", "").match(regexNum);
      if (resultPDP != null) {
        errorePDP += this.$t("key587");
      }
      if (
        this.PDP.trim().length != 5 ||
        this.PDP.trim().replaceAll(":", "").length != 4
      )
        errorePDP += this.$t("key490");
      else {
        if (!this.PDP.includes(":")) errorePDP += this.$t("key491");
        if (
          Number(this.PDP.substring(0, 2)) > 19 ||
          (Number(this.PDP.substring(0, 2)) === 19 &&
            Number(this.PDP.substring(3, 5)) > 30)
        )
          errorePDP += this.$t("key492");
        if (
          Number(this.PDP.substring(0, 2)) < 13 ||
          (Number(this.PDP.substring(0, 2)) === 13 &&
            Number(this.PDP.substring(3, 5)) < 1)
        )
          errorePDP += this.$t("key493");
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePDP += this.$t("key494");
        if (Number(this.PDP.substring(3, 5)) > 59)
          errorePDP += this.$t("key495");
        if (this.PDP == this.PAP) errorePDP += this.$t("key496");
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePDP += this.$t("key494");
        if (
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        )
          errorePDP += this.$t("key494");
      }
      if (errorePDP != "" && vuoto === false) {
        this.DialogErroreOrarioPDP = true;
        this.ErroreOrario = errorePDP;
      }
    },
    CheckPAP() {
      this.ErroreOrario = "";
      var errorePAP = "";
      var vuoto = false;
      if (this.PAP === null || this.PAP === "" || this.PAP === "00:00") {
        vuoto = true;
        this.PAP = "00:00";
      }
      if (this.PAP == null || this.PAP == "") errorePAP += this.$t("key498");
      var regexNum = /[^0-99]/g;
      var resultPAP = this.PAP.replaceAll(":", "").match(regexNum);
      if (resultPAP != null) {
        errorePAP += this.$t("key588");
      }
      if (
        this.PAP.trim().length != 5 ||
        this.PAP.trim().replaceAll(":", "").length != 4
      )
        errorePAP += this.$t("key495");
      else {
        if (!this.PAP.includes(":")) errorePAP += this.$t("key495");
        if (
          Number(this.PAP.substring(0, 2)) > 19 ||
          (Number(this.PAP.substring(0, 2)) === 19 &&
            Number(this.PAP.substring(3, 5)) > 30)
        )
          errorePAP += this.$t("key492");
        if (
          Number(this.PAP.substring(0, 2)) < 13 ||
          (Number(this.PAP.substring(0, 2)) === 13 &&
            Number(this.PAP.substring(3, 5)) < 1)
        )
          errorePAP += this.$t("key493");
        if (
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        )
          errorePAP += this.$t("key503");
        if (Number(this.PAP.substring(3, 5)) > 59)
          errorePAP += this.$t("key495");
        if (this.PDP == this.PAP) errorePAP += this.$t("key505");
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePAP += this.$t("key503");
      }
      if (errorePAP != "" && vuoto === false) {
        this.DialogErroreOrarioPAP = true;
        this.ErroreOrario = errorePAP;
      }
    },
    isIntegerDate(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 58)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    checkChoice() {
      if (this.choice == "0") {
        this.choiceError = true;
        return;
      }
      if (this.choice == "3") {
        this.sendData();
      } else {
        this.confirmChoice = true;
      }
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isFloat(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    pesoRule() {
      var decimaliPeso = "";
      decimaliPeso = this.peso.toString().split(".");
      if (decimaliPeso.length > 1) {
        if (decimaliPeso[1].toString().length > 1) {
          return "Non è possibile inserire più di 1 decimale per il valore del peso";
        }
        if (decimaliPeso[0].toString().trim() === "") {
          return "Inserire un numero davanti ai decimali";
        }
        if (decimaliPeso[1].toString().length == 0) {
          return "Inserire i decimali";
        }
      } else {
        return true;
      }
    },
    sendData() {
      var numeroEmail = "";
      this.noteOrari = "";
      this.validateOrari();

      if (!this.orariError) {
        if (this.choice == "1") {
          numeroEmail = "1";
        } else if (this.choice == "2" && this.choiceDDT == "1") {
          numeroEmail = "2";
        } else if (this.choice == "2" && this.choiceDDT == "2") {
          numeroEmail = "3";
        } else {
          numeroEmail = "4";
        }

        if (!this.soloMattino && !this.soloPomeriggio) {
          this.noteOrari = `Orario mattino: dalle ${this.MDM} alle ${this.MAM}. Orario pomeriggio: dalle ${this.PDP} alle ${this.PAP}.`;
        } else if (this.soloMattino && !this.soloPomeriggio) {
          this.noteOrari = `Ritiro possibile solo al mattino. Orario mattino: dalle ${this.MDM} alle ${this.MAM}.`;
        } else if (this.soloPomeriggio && !this.soloMattino) {
          this.noteOrari = `Ritiro possibile solo al pomeriggio. Orario pomeriggio: dalle ${this.PDP} alle ${this.PAP}.`;
        } else {
          this.noteOrari = "";
        }

        this.overlay = true;
        this.axios
          .post(`${this.$store.state.servicePath}/api/Mail/InvioMailGFF`, {
            data:
              this.choice == "1"
                ? this.date.substr(8, 2) +
                  "-" +
                  this.date.substr(5, 2) +
                  "-" +
                  this.date.substr(0, 4)
                : "00000000",
            numeroColli:
              this.choice == "1"
                ? Number(this.colliDDT) - Number(this.colliMag)
                : 0,
            peso: this.choice == "1" ? this.peso : 0,
            personaRiferimento: this.choice == "1" ? this.riferimento : "",
            numeroGiacenza: this.giacenza,
            allegato: this.choiceDDT == "1" ? this.uploaded : "",
            nomeAllegato: this.choiceDDT == "1" ? this.uploadName : "",
            numeroEmail: numeroEmail,
            noteRitiro: this.choice == "1" ? this.noteOrari : "",
          })
          .then(() => {
            this.overlay = false;
            this.successDialog = true;
          })
          .catch(() => {
            this.overlay = false;
            this.generalError = true;
          });
      }
    },
    close() {
      this.overlay = true;
      location.href = "https://www.arcospedizioni.it";
    },
    fileSelectedFunc(e) {
      var upload = e.target.files[0];
      if (upload != undefined) {
        this.overlay = true;
        if (upload.type === "application/pdf") {
          this.estensione = "pdf";
        } else if (upload.type === "image/jpeg") {
          this.estensione = "jpeg";
        } else if (upload.type === "image/png") {
          this.estensione = "png";
        } else {
          this.overlay = false;
          this.uploadError = true;
        }

        if (!this.uploadError) {
          const fr = new FileReader();
          fr.readAsDataURL(upload);
          this.uploaded = fr.result;
          fr.addEventListener("load", () => {
            this.uploaded = fr.result;
            this.uploadName = upload.name;
            this.overlay = false;
          });
        }
      }
    },
    goBack() {
      this.choiceDDT = "1";
      this.confirmChoice = false;
      this.choice = "0";
    },
    validateOrari() {
      this.Errore = "";
      if (this.MDM === null || this.MDM === "") this.MDM = "00:00";
      if (this.MAM === null || this.MAM === "") this.MAM = "00:00";
      if (this.PDP === null || this.PDP === "") this.PDP = "00:00";
      if (this.PAP === null || this.PAP === "") this.PAP = "00:00";
      var regexNum = /[^0-99]/g;
      var resultMDM = this.MDM.replaceAll(":", "").match(regexNum);
      var resultMAM = this.MAM.replaceAll(":", "").match(regexNum);
      var resultPDP = this.PDP.replaceAll(":", "").match(regexNum);
      var resultPAP = this.PAP.replaceAll(":", "").match(regexNum);

      if (resultMDM != null) {
        this.Errore += this.$t("key577"); //nuovo
        this.MDMColore = "red lighten-4";
        this.orariError = true;
      }
      if (resultMAM != null) {
        this.Errore += this.$t("key578");
        this.MAMColore = "red lighten-4";
        this.orariError = true;
      }
      if (resultPDP != null) {
        this.Errore += this.$t("key579");
        this.PDPColore = "red lighten-4";
        this.orariError = true;
      }
      if (resultPAP != null) {
        this.Errore += this.$t("key580"); //nuovo
        this.PAPColore = "red lighten-4";
        this.orariError = true;
      }
      if (
        this.MDM.trim().length != 5 ||
        this.MDM.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore += this.$t("key471");
        this.MDMColore = "red lighten-4";
        this.orariError = true;
      } else if (this.MDM !== "00:00") {
        if (!this.MDM.includes(":")) {
          this.Errore += this.$t("key471");
          this.MDMColore = "red lighten-4";
          this.orariError = true;
        }
        if (this.MDM[2] != ":") {
          this.Errore += this.$t("key471");
          this.MDMColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          this.MAM !== "00:00" &&
          this.MDM.substring(0, 2) > this.MAM.substring(0, 2)
        ) {
          this.Errore += this.$t("key473");
          this.MDMColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          Number(this.MDM.substring(0, 2)) > 13 ||
          (Number(this.MDM.substring(0, 2)) === 13 &&
            Number(this.MDM.substring(3, 5)) > 0)
        ) {
          this.Errore += this.$t("key474");
          this.MDMColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          Number(this.MDM.substring(0, 2)) < 6 ||
          (Number(this.MDM.substring(0, 2)) === 6 &&
            Number(this.MDM.substring(3, 5)) < 30)
        ) {
          this.Errore += this.$t("key475");
          this.MDMColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          this.MAM !== "00:00" &&
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        ) {
          this.Errore += this.$t("key476");
          this.MDMColore = "red lighten-4";
          this.orariError = true;
        }
        if (this.MDM == this.MAM) {
          this.Errore += this.$t("key477");
          this.MDMColore = "red lighten-4";
          this.orariError = true;
        }
        if (Number(this.MDM.substring(3, 5)) > 59) {
          this.Errore += this.$t("key478");
          this.MDMColore = "red lighten-4";
          this.orariError = true;
        }
      }
      if (
        this.MAM.trim().length != 5 ||
        this.MAM.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore += this.$t("key483");
        this.MAMColore = "red lighten-4";
        this.orariError = true;
      } else if (this.MAM !== "00:00") {
        if (!this.MAM.includes(":")) {
          this.Errore += this.$t("key482");
          this.MAMColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          Number(this.MAM.substring(0, 2)) > 13 ||
          (Number(this.MAM.substring(0, 2)) === 13 &&
            Number(this.MAM.substring(3, 5)) > 0)
        ) {
          this.Errore += this.$t("key474");
          this.MAMColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          Number(this.MDM.substring(0, 2)) < 6 ||
          (Number(this.MDM.substring(0, 2)) === 6 &&
            Number(this.MDM.substring(3, 5)) < 30)
        ) {
          this.Errore += this.$t("key484");
          this.MAMColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          this.MDM !== "00:00" &&
          this.MDM.substring(0, 2) > this.MAM.substring(0, 2)
        ) {
          this.Errore += this.$t("key485");
          this.MAMColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          this.MDM !== "00:00" &&
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        ) {
          this.Errore += this.$t("key485");
          this.MAMColore = "red lighten-4";
          this.orariError = true;
        }
        if (this.MDM == this.MAM) {
          this.Errore += this.$t("key487");
          this.MAMColore = "red lighten-4";
          this.orariError = true;
        }
        if (Number(this.MAM.substring(3, 5)) > 59) {
          this.Errore += this.$t("key488");
          this.MAMColore = "red lighten-4";
          this.orariError = true;
        }
      }
      if (
        this.PDP.trim().length != 5 ||
        this.PDP.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore += this.$t("key490");
        this.PDPColore = "red lighten-4";
        this.orariError = true;
      } else if (this.PDP !== "00:00") {
        if (!this.PDP.includes(":")) {
          this.Errore += this.$t("key491");
          this.PDPColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          Number(this.PDP.substring(0, 2)) > 19 ||
          (Number(this.PDP.substring(0, 2)) === 19 &&
            Number(this.PDP.substring(3, 5)) > 30)
        ) {
          this.Errore += this.$t("key492");
          this.PDPColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          Number(this.PDP.substring(0, 2)) < 13 ||
          (Number(this.PDP.substring(0, 2)) === 13 &&
            Number(this.PDP.substring(3, 5)) < 1)
        ) {
          this.Errore += this.$t("key493");
          this.PDPColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) > this.PAP.substring(0, 2)
        ) {
          this.Errore += this.$t("key494");
          this.PDPColore = "red lighten-4";
          this.orariError = true;
        }
        if (Number(this.PDP.substring(3, 5)) > 59) {
          this.Errore += this.$t("key495");
          this.PDPColore = "red lighten-4";
          this.orariError = true;
        }
        if (this.PDP == this.PAP) {
          this.Errore += this.$t("key496");
          this.PDPColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        ) {
          this.Errore += this.$t("key494");
          this.PDPColore = "red lighten-4";
          this.orariError = true;
        }
      }
      if (
        this.PAP.trim().length != 5 ||
        this.PAP.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore += this.$t("key495");
        this.PAPColore = "red lighten-4";
        this.orariError = true;
      } else if (this.PAP !== "00:00") {
        if (!this.PAP.includes(":")) {
          this.Errore += this.$t("key495");
          this.PAPColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          Number(this.PAP.substring(0, 2)) > 19 ||
          (Number(this.PAP.substring(0, 2)) === 19 &&
            Number(this.PAP.substring(3, 5)) > 30)
        ) {
          this.Errore += this.$t("key492");
          this.PAPColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          Number(this.PAP.substring(0, 2)) < 13 ||
          (Number(this.PAP.substring(0, 2)) === 13 &&
            Number(this.PAP.substring(3, 5)) < 1)
        ) {
          this.Errore += this.$t("key493");
          this.PAPColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        ) {
          this.Errore += this.$t("key503");
          this.PAPColore = "red lighten-4";
          this.orariError = true;
        }
        if (Number(this.PAP.substring(3, 5)) > 59) {
          this.Errore += this.$t("key495");
          this.PAPColore = "red lighten-4";
          this.orariError = true;
        }
        if (this.PDP == this.PAP) {
          this.Errore += this.$t("key505");
          this.PAPColore = "red lighten-4";
          this.orariError = true;
        }
        if (
          this.PDP !== "00:00" &&
          this.PDP.substring(0, 2) > this.PAP.substring(0, 2)
        ) {
          this.Errore += this.$t("key503");
          this.PAPColore = "red lighten-4";
          this.orariError = true;
        }
      }
      if (this.soloMattino && (this.MDM === "00:00" || this.MAM === "00:00")) {
        this.Errore += this.$t("key544");
        this.orariError = true;
      }

      if (
        this.soloPomeriggio &&
        (this.PDP === "00:00" || this.PAP === "00:00")
      ) {
        this.Errore += this.$t("key545");
        this.orariError = true;
      }

      if (this.MAM === "00:00" && this.MDM !== "00:00") {
        this.Errore += this.$t("key479");
        this.orariError = true;
      } else {
        if (this.MAM !== "00:00" && this.MDM === "00:00") {
          this.Errore += this.$t("key480");
          this.orariError = true;
        }
      }
      if (this.PDP === "00:00" && this.PAP !== "00:00") {
        this.Errore += this.$t("key581"); //nuovo
        this.orariError = true;
      } else {
        if (this.PDP !== "00:00" && this.PAP === "00:00") {
          this.Errore += this.$t("key582"); //nuovo
          this.orariError = true;
        }
      }
      if (this.MDM === "00:00" && this.MAM === "00:00") {
        if (this.PAP === "00:00" && this.PDP === "00:00") {
          this.Errore += this.$t("key546");
          this.orariError = true;
        } else if (this.PAP !== "00:00" && this.PDP !== "00:00") {
          this.soloPomeriggio = true;
          this.soloMattino = false;
        }
      }
      if (this.PAP === "00:00" && this.PDP === "00:00") {
        if (this.MDM !== "00:00" && this.MAM !== "00:00") {
          this.soloMattino = true;
          this.soloPomeriggio = false;
        }
      }
      if (this.soloMattino) {
        this.PDP = "00:00";
        this.PAP = "00:00";
      }
      if (this.soloPomeriggio) {
        this.MAM = "00:00";
        this.MDM = "00:00";
      }
    },
  },
  watch: {
    MDM: function (value) {
      if (value.length == 2) this.MDM = value + ":";
    },
    MAM: function (value) {
      if (value.length == 2) this.MAM = value + ":";
    },
    PAP: function (value) {
      if (value.length == 2) this.PAP = value + ":";
    },
    PDP: function (value) {
      if (value.length == 2) this.PDP = value + ":";
    },
    soloMattino: function (value) {
      if (value) this.soloPomeriggio = false;
    },
    soloPomeriggio: function (value) {
      if (value) this.soloMattino = false;
    },
  },
};
</script>