<template>
  <v-app :style="{ background: $vuetify.theme.themes.light.background }">
    <v-app-bar color="white" app>
      <v-toolbar-title class="headline">
        <img height="40px" src="./assets/logo.svg" />
        <h4 class="titolo">{{ $t("key001") }}</h4>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn large color="#151b42" text @click="italiano()">ITA</v-btn>&nbsp;
      |&nbsp;
      <v-btn large color="#151b42" text @click="english()">ENG</v-btn>
      <v-badge
        :content="$store.state.avvisiNonLetti.length"
        :value="$store.state.avvisiNonLetti.length"
        color="red"
        overlap
        v-if="$route.path != '/' && $route.path != '/errore' && $route.path != '/registrazione' && $route.name != 'ResetPassword'"
      >
        <v-icon
          class="ml-2"
          color="#151b42"
          style="font-size: 32px"
          @click="
            $route.path != '/avvisi' ? $router.push({ path: `/avvisi` }) : ''
          "
          >mdi-bell</v-icon
        >
      </v-badge>
      <v-icon
        class="ml-4"
        color="#151b42"
        large
        @click.stop="
          $route.path != '/benvenuto'
            ? $router.push({ path: `/benvenuto` })
            : ''
        "
        v-if="$route.path != '/' && $route.path != '/errore' && $route.path != '/registrazione' && $route.name != 'ResetPassword'"
        >mdi-home</v-icon
      >
    </v-app-bar>

    <!-- <v-navigation-drawer
      fixed
      v-model="drawer"
      app
      dark
      class="secondary"
      stateless
      right
      value="true"
      color="#151B42"
      
    > -->

    <!-- SE SCOMMENTATO DA PORTARE A VUETIFY 2-->
    <!-- <v-navigation-drawer
      style="background-color: #151b42"
      v-model="drawer"
      app
      right
      temporary
    >
      <v-toolbar text color="#151B42">
        <v-list>
          <v-list-tile>
            <v-list-tile-title style="color: primary" class="title">{{
              $t("key351")
            }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-toolbar>
      <v-btn text style="color: white" @click="english">English</v-btn>
      <v-btn text style="color: white" @click="italiano">Italiano</v-btn>
      <v-divider></v-divider>
      <v-list>
        <v-list-tile
          value="true"
          v-for="(item, i) in items"
          :key="i"
          @click="Redirect(item.route != '' ? item.route : item.href)"
          :disabled="item.disabled"
        >
          <v-list-tile-action>
            <v-icon
              :style="
                $route.path === item.route ||
                $route.path.split('/', 2)[1] === item.route.split('/', 2)[1]
                  ? 'color: primary'
                  : 'color: white'
              "
              v-html="item.icon"
            ></v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title
              :style="
                $route.path === item.route ||
                $route.path.split('/', 2)[1] === item.route.split('/', 2)[1]
                  ? 'color: primary'
                  : 'color: white'
              "
              v-text="item.title"
            ></v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
      <v-btn text style="color: primary" @click="logout">Logout</v-btn>
    </v-navigation-drawer> -->
    <v-main>
      <v-card v-if="visualizzaRefresh">
        <v-card-title
          >Gentile cliente, stai visualizzando una versione dell'Area Clienti
          non aggiornata. Clicca sul pulsante "Ricarica" per scaricare l'ultima
          versione (verrai reindirizzato alla pagina di login).
          <v-btn
            style="box-shadow: none; color: white"
            color="primary"
            @click="ricarica()"
            >Ricarica</v-btn
          ></v-card-title
        >
        <v-card-actions></v-card-actions>
      </v-card>
      <router-view v-if="!loadingPagineLogistica" />
    </v-main>
    <v-layout justify-center>
      <v-dialog v-model="errore_cappario" persistent max-width="600">
        <v-card>
          <v-card-title class="headline"
            >ERRORE DI CARICAMENTO CAPPARIO</v-card-title
          >
          <v-card-text
            >Gentile utente, si è verificato un errore con il caricamento del
            cappario. Ti preghiamo di procedere nuovamente al login sui nostri
            sistemi prima di procedere</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="ErroreCappario()"
              >TORNA AL LOGIN</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      drawer: false,
      items: [{}],
      errore_cappario: false,
      visualizzaRefresh: false,
      loadingPagineLogistica: false, //per aspettare di aver creato nuovo token per primo sottocodice di codice master (quando si va sulle pagine di logistica)
    };
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "errore_cappario" && mutation.payload === true) {
        this.errore_cappario = true;
      }
    });
    this.items = [];
  },
  created() {
    if (navigator.language == "it-IT" || navigator.language == "it") {
      this.axios.defaults.headers.common["Language"] = "it-IT";
      this.$i18n.locale = "it";
      this.$vuetify.lang.current = "it";
      document.documentElement.setAttribute("lang", "it");
    } else {
      this.axios.defaults.headers.common["Language"] = "en-US";
      this.$i18n.locale = "en";
      this.$vuetify.lang.current = "en";
      document.documentElement.setAttribute("lang", "en");
    }
    if (
      this.$route.name == null ||
      this.$route.name == undefined ||
      this.$route.name.toLowerCase().includes("piombi")
    ) {
      this.drawer = false;
      this.$on("benvenuto", () => {
        this.loadMenu();
      });
    } else {
      if (this.$route.name.toLowerCase().includes("prenotazioneconsegna"))
        this.drawer = false;
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArDatiApps/1/" +
            this.$store.state.version
        )
        .then((res) => {
          if (res.data) {
            this.visualizzaRefresh = false;
          } else {
            this.visualizzaRefresh = true;
          }
        });
      // this.$vuetify.lang.current = "it";
      this.$on("benvenuto", () => {
        this.loadMenu();
      });
    }
  },
  methods: {
    ricarica() {
      this.$router.push({ path: `/` });
      location.reload(true);
    },
    Redirect(link) {
      if (link.includes("http")) {
        window.open(link);
      } else {
        if (!link.includes("Tracking")) {
          this.axios
            .get(
              this.$store.state.servicePath +
                "/api/Login/GeneraNuovoToken/" +
                this.$store.state.CodiceOriginale
            )
            .then((res) => {
              this.$store.state.token = res.data;
              this.axios.defaults.headers.common["Authorization"] = res.data;

              this.$router.push({ path: link });
            })
            .catch(() => {
              this.$router.push({ path: `/errore` });
            });
        } else {
          this.$router.push({ path: link });
        }
      }
    },
    loadMenu() {
      this.items = [];
      this.$store.state.userdata.menus.forEach((element) => {
        var eldes = element.descrizione;
        if (this.axios.defaults.headers.common["Language"] != "it-IT") {
          if (element.descrizione == "Le tue fatture e note di credito") {
            eldes = "Invoices and credit notes";
          }
          if (element.descrizione == "Rubrica") {
            eldes = "Database";
          }
          if (element.descrizione == "Inserimento ritiri") {
            eldes = "Enter pick up order";
          }
          if (element.descrizione == "Località impervie") {
            eldes = "Remote localities";
          }
          if (element.descrizione == "Località disagiate – facchinaggio automatico") {
            eldes = "Deprived areas – automatic handling";
          }
          if (element.descrizione == "Bollettazione") {
            eldes = "Enter shipment";
          }
          if (element.descrizione == "Nuovi Ritiri") {
            eldes = "Enter a pick up order";
          }
        }
        var el = {
          icon: element.icona,
          title: eldes,
          route: element.route,
          href: element.href,
          disabled: false,
        };
        this.items.push(el);
      });
    },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.items = [];
        this.$router.push("/");
      });
    },
    ErroreCappario() {
      this.errore_cappario = false;
      window.location.href = "/#/";
    },
    english() {
      this.$i18n.locale = "en";
      this.axios.defaults.headers.common["Language"] = "en-US";
      this.$vuetify.lang.current = "en";
      document.documentElement.setAttribute("lang", "en");
      // navigator.language = 'en';
      this.items = [];
      if (this.$store.state.userdata.menus != undefined) {
        this.$store.state.userdata.menus.forEach((element) => {
          var eldes = element.descrizione;
          if (element.descrizione == "Le tue fatture e note di credito") {
            eldes = "Invoices and credit notes";
          }
          if (element.descrizione == "Rubrica") {
            eldes = "Database";
          }
          if (element.descrizione == "Inserimento ritiri") {
            eldes = "Enter pick up order";
          }
          if (element.descrizione == "Località impervie") {
            eldes = "Remote localities";
          }
          if (element.descrizione == "Località disagiate – facchinaggio automatico") {
            eldes = "Deprived areas – automatic handling";
          }
          if (element.descrizione == "Bollettazione") {
            eldes = "Enter shipment";
          }
          if (element.descrizione == "Località disagiate Francia") {
            eldes = "France remote localities";
          }
          if (element.descrizione == "Località balneari") {
            eldes = "Seaside localities";
          }
          if (element.descrizione == "Manuale bollettazione") {
            eldes = "Enter shipment manual";
          }
          if (element.descrizione == "Tracking spedizioni") {
            eldes = "Shipments tracking";
          }
          if (element.descrizione == "Tracking ritiri") {
            eldes = "Pick up orders tracking";
          }
          if (element.descrizione == "Download POD massivo") {
            eldes = "Massive POD download";
          }
          if (element.descrizione == "Località disagiate/impervie/balneari") {
            eldes = "Remote localities";
          }
          if (element.descrizione === "Nuovi Ritiri") {
            eldes = "Enter a pick up order";
          }
          var el = {
            icon: element.icona,
            title: eldes,
            route: element.route,
            href: element.href,
            disabled: false,
          };
          this.items.push(el);
        });
      }
    },
    italiano() {
      this.$i18n.locale = "it";
      this.axios.defaults.headers.common["Language"] = "it-IT";
      this.$vuetify.lang.current = "it";
      document.documentElement.setAttribute("lang", "it");

      this.items = [];
      if (this.$store.state.userdata.menus != undefined) {
        this.$store.state.userdata.menus.forEach((element) => {
          var el = {
            icon: element.icona,
            title: element.descrizione,
            route: element.route,
            href: element.href,
            disabled: false,
          };
          this.items.push(el);
        });
      }
    },
    generaToken(nomePaginaTo) {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Login/GeneraNuovoToken/" +
            this.$store.state.codicePerCambioToken
        )
        .then((res) => {
          this.$store.state.token = res.data;
          this.axios.defaults.headers.common["Authorization"] = res.data;
          if (nomePaginaTo === "nuoviritiri")
            this.$store.dispatch("getCodiciValidi", "ritiri");
          if (nomePaginaTo === "trackinglistnew")
            this.$store.dispatch("getCodiciValidi", "trackingSpedizioni");
          if (nomePaginaTo === "trackingritirilist")
            this.$store.dispatch("getCodiciValidi", "trackingRitiri");
          if (nomePaginaTo === "kpi")
            this.$store.dispatch("getCodiciValidi", "ritiri");
          if (nomePaginaTo === "cruscottocliente")
            this.$store.dispatch("getCodiciValidi", "ritiri");
          if (nomePaginaTo === "giacenzelogistica")
            this.$store.dispatch("getCodiciValidi", "logistica");
          if (nomePaginaTo === "movimenti")
            this.$store.dispatch("getCodiciValidi", "logistica");
          if (nomePaginaTo === "documentiingresso")
            this.$store.dispatch("getCodiciValidi", "logistica");
          if (nomePaginaTo === "documentiuscita")
            this.$store.dispatch("getCodiciValidi", "logistica");
          if (nomePaginaTo === "aggiungidocumentodiuscitalist")
            this.$store.dispatch("getCodiciValidi", "logistica");
          if (nomePaginaTo === "aggiungidocumentodiuscitadetail")
            this.$store.dispatch("getCodiciValidi", "logistica");
          this.loadingPagineLogistica = false;
        })
        .catch((err) => {
          console.log(err);
          this.loadingPagineLogistica = false;
          this.$router.push({ path: `/errore` });
        });
    },
  },
  watch: {
    $route(to, from) {
      if (to == null || to == undefined) return;
      if (to.name.toLowerCase().includes("piombi")) {
        this.drawer = false;
        return;
      }
      if (to.name.toLowerCase().includes("prenotazioneconsegna"))
        this.drawer = false;
      var reset = true;
      if (
        (from.name.toLowerCase() === "trackingritiridetail" &&
          to.name.toLowerCase() === "trackingritirilist") ||
        (from.name.toLowerCase() === "trackingdetail" &&
          to.name.toLowerCase() === "trackinglistnew") ||
        (from.name.toLowerCase() === "trackingritirilist" &&
          to.name.toLowerCase() === "trackingritiridetail") ||
        (from.name.toLowerCase() === "trackinglistnew" &&
          to.name.toLowerCase() === "trackingdetail") ||
        (from.name.toLowerCase() === "trackingritiridetail" &&
          to.name.toLowerCase() === "trackingdetail") ||
        (from.name.toLowerCase() === "trackingdetail" &&
          to.name.toLowerCase() === "trackingritiridetail")
      ) {
        reset = false;
      }
      if (
        !to.name.toLowerCase().includes("prenotazioneconsegna") &&
        !to.name.toLowerCase().includes("login") &&
        reset &&
        !to.name.toLowerCase().includes("registrazione") &&
        !to.name.toLowerCase().includes("password")
      ) {
        //   && !((to.name.toLowerCase( )=== "trackingritirilist" || from.name.toLowerCase( )=== "trackingritirilist" ||
        // from.name.toLowerCase()==="trackingritiridetail" || to.name.toLowerCase()==="trackingritiridetail") &&
        // (to.name.toLowerCase() === "trackinglistnew" || from.name.toLowerCase() === "trackinglistnew" || to.name.toLowerCase() === "trackingdetail" ||
        // from.name.toLowerCase() === "trackingdetail"))
        // if(this.$store.state.visualizzaSelectMaster){
        this.$store.state.filtri_tracking_ritiri = {};
        this.$store.state.filtri_tracking = {};
        this.$store.state.items_tracking_ritiri = [];
        this.$store.state.items_tracking = [];
        // }

        // Non entro se cambio pagina tra aggiungidocumentodiuscitalist e aggiungidocumentodiuscitadetail perchè devo tenere il JTW del codice (master) selezionato
        if (
          !(
            from.name.toLowerCase() == "aggiungidocumentodiuscitalist" &&
            to.name.toLowerCase() == "aggiungidocumentodiuscitadetail"
          ) &&
          !(
            from.name.toLowerCase() == "aggiungidocumentodiuscitadetail" &&
            to.name.toLowerCase() == "aggiungidocumentodiuscitalist"
          )
        ) {
          this.$store.state.codicePerCambioToken =
            this.$store.state.CodiceOriginale;
          if (this.$store.state.masterBloccatoLogistica && to.meta.logistica) {
            //Serve per generare il token per primo sottocodice di codice master quando il master non ha accesso a logistica
            this.loadingPagineLogistica = true;
            this.$store.dispatch("getCodiciValidi", "logistica").then(() => {
              if (this.$store.state.codiciDaVisualizzare.length > 0) {
                this.$store.state.MasterSelezionato =
                  this.$store.state.codiciDaVisualizzare[0];
                this.$store.state.codicePerCambioToken =
                  this.$store.state.MasterSelezionato.value;
              }
              this.generaToken(to.name.toLowerCase());
            });
          } else {
            this.generaToken(to.name.toLowerCase());
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: "hk-grotesk";
  src: local("hk-grotesk"),
    url(./fonts/hk-grotesk/HKGrotesk-Regular.otf) format("truetype");
}

.highlighted-arco {
  color: primary;
}
html,
body {
  font-family: "hk-grotesk";
  color: #151b42;
}
#app {
  font-family: "hk-grotesk";
  color: #151b42;
}
.v-slider__thumb {
  cursor: pointer;
  height: 42px;
  width: 42px;
}
.centered-input {
  text-align: center;
}
.titolo {
  font-family: "hk-grotesk";
  margin-top: -40px;
  margin-left: 180px;
  color: #151b42;
}
p {
  font-family: "hk-grotesk";
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "hk-grotesk";
}
input {
  font-family: "hk-grotesk";
}
</style>