<template>
  <v-container>
    <v-card>
      <v-layout justify-center>
        <v-dialog v-model="overlay" persistent max-width="600">
          <v-card>
            <v-card-text class="pt-5">
              <v-progress-linear
                indeterminate
                color="primary"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-layout>
      <v-dialog v-model="dialogErroreInserimento" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key566") }}</v-card-title>
          <v-card-text v-html="erroreInserimentoRecord">{{
            erroreInserimentoRecord
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              style="color: white"
              color="primary"
              @click="dialogErroreInserimento = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogok" persistent max-width="600">
        <v-card>
          <v-card-title class="headline" v-html="inserimentook">{{
            inserimentook
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              style="color: white"
              color="primary"
              @click="
                dialogok = false;
                Indietro();
              "
              >Indietro</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogErrore" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key566") }}</v-card-title>
          <v-card-text v-html="erroreInserimento">{{
            erroreInserimento
          }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              style="color: white"
              color="primary"
              @click="logout()"
              >Logout</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="DialogErroreOrarioMAM" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key446") }}</v-card-title>
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-text v-html="ErroreOrarioMattino"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              style="color: white"
              color="primary"
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                focusNextTick($refs.mamText);
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="DialogErroreOrarioPDP" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key447") }}</v-card-title>
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              style="color: white"
              color="primary"
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                focusNextTick($refs.pdpText);
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="DialogErroreOrarioPAP" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key448") }}</v-card-title>
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              style="color: white"
              color="primary"
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                focusNextTick($refs.papText);
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="DialogErroreOrario" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ $t("key449") }}</v-card-title>
          <v-card-text v-html="ErroreOrario"></v-card-text>
          <v-card-text v-html="ErroreOrarioMattino"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              style="color: white"
              color="primary"
              @click="
                DialogErroreOrarioMAM = false;
                DialogErroreOrario = false;
                DialogErroreOrarioPDP = false;
                DialogErroreOrarioPAP = false;
                focusNextTick($refs.mdmText);
              "
              >CHIUDI</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card-text>
        <v-form ref="form">
          <h1 class="mb-5" style="color: black">Riepilogo azienda</h1>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('key006')"
                v-model="ragioneSociale"
                outlined
                readonly
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                :label="$t('key007')"
                v-model="indirizzo"
                outlined
                readonly
                hide-details
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3">
              <v-select
                :items="nazioni"
                v-model="nazione"
                :label="$t('key375')"
                @change="nazione_selezionata = nazione.value"
                outlined
                readonly
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                :label="$t('key008')"
                v-model="comune"
                outlined
                readonly
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                :label="$t('key009')"
                v-model="cap"
                outlined
                readonly
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                :label="$t('key010')"
                v-model="provincia"
                outlined
                readonly
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
          <h1 class="mb-5 mt-7" style="color: black">
            Orari di apertura / chiusura
          </h1>
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field
                :label="$t('key016')"
                ref="mdmText"
                v-model="MDM"
                required
                outlined
                @keypress="isIntegerDate($event)"
                @blur="CheckMDM"
                :background-color="MDMColore"
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                :label="$t('key017')"
                ref="mamText"
                v-model="MAM"
                required
                outlined
                @keypress="isIntegerDate($event)"
                @blur="CheckMAM"
                :background-color="MAMColore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                :label="$t('key018')"
                ref="pdpText"
                v-model="PDP"
                required
                outlined
                @keypress="isIntegerDate($event)"
                @blur="CheckPDP"
                @focus="CheckMattino"
                :background-color="PDPColore"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                :label="$t('key019')"
                ref="papText"
                v-model="PAP"
                required
                outlined
                @keypress="isIntegerDate($event)"
                @blur="CheckPAP"
                :background-color="PAPColore"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <h1 class="mb-5 mt-7" style="color: black">{{ $t("key020") }}</h1>
          <v-row style="max-width: 1000px">
            <v-col row>
              <h3 style="color: black">{{ $t("key021") }}:</h3>
              <v-radio-group v-model="lunedi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col row>
              <h3 style="color: black">{{ $t("key022") }}:</h3>
              <v-radio-group v-model="martedi" class="mt-1">
                <v-spacer></v-spacer>
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col row>
              <h3 style="color: black">{{ $t("key023") }}:</h3>
              <v-radio-group v-model="mercoledi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col row>
              <h3 style="color: black">{{ $t("key024") }}:</h3>
              <v-radio-group v-model="giovedi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>

            <v-col row>
              <h3 style="color: black">{{ $t("key025") }}:</h3>
              <v-radio-group v-model="venerdi" class="mt-1">
                <v-radio
                  v-for="n in 4"
                  :key="n"
                  :label="Radio[n - 1]"
                  :value="n"
                  class="mb-2"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title class="mt-4"
        ><h2>Gestione invio mail fatture</h2></v-card-title
      >
      <v-card-text>
        <v-form>
          <v-layout>
            <v-flex xs12 sm12 md3>
              <v-checkbox v-model="invioFatture" label="Abilita invio mail" />
            </v-flex>
            <v-flex xs6 sm6 md4 class="ml-2">
              <v-text-field
                v-if="invioFatture"
                v-model="mail1Fatture"
                label="mail 1"
                outlined
              />
            </v-flex>
            <v-flex xs6 sm6 md4 class="ml-2">
              <v-text-field
                v-if="invioFatture"
                v-model="mail2Fatture"
                label="mail 2"
                outlined
              />
            </v-flex>
            <v-flex xs0 sm0 md1> </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="!menuBol">
        <v-btn
          large
          depressed
          style="color: white"
          color="primary"
          @click="NormalizzaCampi"
          >{{ $t("key133") }}</v-btn
        >
        <v-btn
          large
          depressed
          style="color: white"
          color="#A0A4A7"
          @click="Indietro"
          >{{ $t("key260") }}</v-btn
        >
      </v-card-actions>
    </v-card>
    <!-- SALVATAGGIO AUTOMATICO IN RUBRICA -->
    <v-card v-if="showSalvAut">
      <v-card-title class="mt-4"
        ><h2>
          {{ $t("key624") }}
        </h2></v-card-title
      >
      <v-card-text>
        <v-form>
          <v-layout>
            <v-flex xs12 sm12>
              <p class="v-label theme--light">
                {{ $t("key625") }}
              </p>
              <v-radio-group v-model="tipoSalvataggio">
                <v-radio
                  v-for="(tipo, i) in listaSalvataggioAut"
                  :key="i"
                  :label="tipo"
                  :value="i"
                ></v-radio>
              </v-radio-group>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
    <!-- STAMPA CON LOGO ARCO -->
    <v-card v-if="mostraSelezioneLogo">
      <v-card-title class="mt-4"
        ><h2>
          {{ $t("key628") }}
        </h2></v-card-title
      >
      <v-card-text>
        <v-form>
          <v-layout>
            <v-flex xs12 sm12>
              <v-checkbox v-model="stampaLogo" :label="$t('key629')" />
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- FORZA DISPOSIZIONE ESPRESSO -->
    <v-card v-if="menuBol">
      <v-card-title class="mt-4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <h2 v-bind="attrs" v-on="on">
              {{ $t("key713") }}
            </h2>
          </template>
          <span>{{ $t("key715") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-layout>
            <v-flex xs12 sm12>
              <v-checkbox v-model="forzaEspresso" :label="$t('key714')" />
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>

    <!-- FORZA INVIO TRACKING -->
    <v-card v-if="menuBol">
      <v-card-title class="mt-4">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <h2 v-bind="attrs" v-on="on">
              {{ $t("key906") }}
            </h2>
          </template>
          <span>{{ $t("key905") }}</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-layout>
            <v-flex xs12 sm12>
              <v-checkbox v-model="forzaInvioTracking" :label="$t('key907')" />
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          large
          depressed
          style="color: white"
          color="primary"
          @click="NormalizzaCampi"
          >{{ $t("key133") }}</v-btn
        >
        <v-btn
          large
          depressed
          style="color: white"
          color="#A0A4A7"
          @click="Indietro"
          >{{ $t("key260") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      invioFatture: false,
      mail1Fatture: "",
      mail2Fatture: "",
      overlay: false,
      ragioneSociale: "",
      indirizzo: "",
      nazioni: [],
      nazione: "",
      comune: "",
      cap: "",
      provincia: "",
      DialogErroreOrarioMAM: false,
      DialogErroreOrarioPDP: false,
      DialogErroreOrarioPAP: false,
      DialogErroreOrario: false,
      ErroreOrario: "",
      ErroreOrarioMattino: "",
      Radio_it: ["Mai", "Mattina", "Pomeriggio", "Tutto il giorno"],
      Radio_en: ["Never", "Morning", "Afternoon", "All day"],
      Radio: [],
      lunedi: 1,
      martedi: 1,
      mercoledi: 1,
      giovedi: 1,
      venerdi: 1,
      MAM: "13:00",
      MDM: "08:00",
      PDP: "13:30",
      PAP: "18:00",
      MDMColore: "",
      MAMColore: "",
      PAPColore: "",
      PDPColore: "",
      dialogErrore: false,
      erroreInserimento: "",
      giochi: "",
      dialogok: false,
      inserimentook: "",
      dialogErroreInserimento: false,
      erroreInserimentoRecord: "",
      Errore: "",
      perIdentificativo: 0,
      tipoSalvataggio: 0,
      listaSalvataggioAut: [],
      menuBol: false,
      showSalvAut: false,
      mostraSelezioneLogo: false,
      stampaLogo: false,
      forzaEspresso: false,
      forzaInvioTracking: false,
    };
  },
  created() {
    this.$store.dispatch("ImpostaScollegamento");
  },
  mounted() {
    this.overlay = true;
    this.pageLang = this.axios.defaults.headers.common["Language"];
    if (this.$i18n.locale == "it") {
      this.listaSalvataggioAut = ["Mai", "Sempre", "Con conferma"];
    } else {
      this.listaSalvataggioAut = ["Never", "Always", "With confirmation"];
    }

    this.menuBol = this.$store.state.userdata.menus.some(
      (menu) => menu.route == "/Bollettazione"
    );
    this.axios
      .get(
        this.$store.state.servicePath + "/api/ArUtentiPermessis/Check/VIS-IDCLI"
      )
      .then((res) => {
        this.perIdentificativo = res.data;
        if (this.menuBol) {
          if (this.perIdentificativo === 1 || this.perIdentificativo === "1") {
            this.showSalvAut = false;
          } else {
            this.showSalvAut = true;
          }
        } else {
          this.showSalvAut = false;
        }
      });

    if (this.menuBol) {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/ArUtentiPermessis/Check/PRT-10x10"
        )
        .then((res) => {
          if (res.data === 1) {
            this.mostraSelezioneLogo = true;
          } else {
            this.mostraSelezioneLogo = false;
          }
        })
        .catch((err) => {
          this.mostraSelezioneLogo = false;
        });
    } else {
      this.mostraSelezioneLogo = false;
    }

    this.axios
      .get(this.$store.state.servicePath + "/api/ArVwNazionis")
      .then((res) => {
        res.data.forEach((el) => {
          var element = { text: el.tbdnaz.trim(), value: el.tbcnaz };
          this.nazioni.push(element);
        });
      })
      .catch(() => {
        this.overlay = false;
        this.erroreInserimento = "";
        this.overlay = false;
        this.dialogErrore = true;
        this.erroreInserimento = this.$t("key506");
      });
    if (this.$i18n.locale == "it") {
      this.Radio = this.Radio_it;
    } else {
      this.Radio = this.Radio_en;
    }
    this.axios
      .get(this.$store.state.servicePath + "/api/Ttrcd10f/Rubrica")
      .then((res) => {
        this.ragioneSociale = res.data.trcrgs
          .substr(0, 40)
          .replaceAll("''", "'")
          .replaceAll("`", "'");
        this.indirizzo = res.data.trcind
          .substr(0, 40)
          .replaceAll("''", "'")
          .replaceAll("`", "'");
        this.comune = res.data.trcloc
          .substr(0, 30)
          .replaceAll("''", "'")
          .replaceAll("`", "'");
        this.provincia = res.data.trcprv
          .replaceAll("''", "'")
          .replaceAll("`", "'")
          .substr(0, 2);
        this.cap = res.data.trccap
          .replaceAll("''", "'")
          .replaceAll("`", "'")
          .substr(0, 5);
        this.nazione = res.data.trcnaz;
        this.axios
          .get(this.$store.state.servicePath + "/api/ArGestioneUtenzas", {})
          .then((r) => {
            if (
              r.data.salvataggioAutomaticoRubrica != null &&
              r.data.salvataggioAutomaticoRubrica != "" &&
              r.data.salvataggioAutomaticoRubrica != undefined
            ) {
              this.tipoSalvataggio = r.data.salvataggioAutomaticoRubrica;
            } else {
              this.tipoSalvataggio = 0;
            }

            if (
              r.data.logoSuEtichetta != null &&
              r.data.logoSuEtichetta != "" &&
              r.data.logoSuEtichetta != undefined
            ) {
              this.stampaLogo = r.data.logoSuEtichetta == 0 ? false : true;
            } else {
              this.stampaLogo = false;
            }

            if (
              r.data.forzaEspresso != null &&
              r.data.forzaEspresso != "" &&
              r.data.forzaEspresso != undefined
            ) {
              this.forzaEspresso = r.data.forzaEspresso == 0 ? false : true;
            } else {
              this.forzaEspresso = false;
            }

            if (
              r.data.forzaInvioTracking != null &&
              r.data.forzaInvioTracking != "" &&
              r.data.forzaInvioTracking != undefined
            ) {
              this.forzaInvioTracking =
                r.data.forzaInvioTracking == 0 ? false : true;
            } else {
              this.forzaInvioTracking = false;
            }

            if (r.data.giorniChiusura !== "not found") {
              this.MDM =
                r.data.oraAperturaMattino.substring(0, 2) +
                ":" +
                r.data.oraAperturaMattino.substring(2, 4);
              this.MAM =
                r.data.oraChiusuraMattino.substring(0, 2) +
                ":" +
                r.data.oraChiusuraMattino.substring(2, 4);
              this.PDP =
                r.data.oraAperturaPomeriggio.substring(0, 2) +
                ":" +
                r.data.oraAperturaPomeriggio.substring(2, 4);
              this.PAP =
                r.data.oraChiusuraPomeriggio.substring(0, 2) +
                ":" +
                r.data.oraChiusuraPomeriggio.substring(2, 4);
              this.lunedi =
                r.data.giorniChiusura.substring(0, 1) === " "
                  ? 1
                  : r.data.giorniChiusura.substring(0, 1) === "M"
                  ? 2
                  : r.data.giorniChiusura.substring(0, 1) === "P"
                  ? 3
                  : r.data.giorniChiusura.substring(0, 1) === "T"
                  ? 4
                  : "";
              this.martedi =
                r.data.giorniChiusura.substring(1, 2) === " "
                  ? 1
                  : r.data.giorniChiusura.substring(1, 2) === "M"
                  ? 2
                  : r.data.giorniChiusura.substring(1, 2) === "P"
                  ? 3
                  : r.data.giorniChiusura.substring(1, 2) === "T"
                  ? 4
                  : "";
              this.mercoledi =
                r.data.giorniChiusura.substring(2, 3) === " "
                  ? 1
                  : r.data.giorniChiusura.substring(2, 3) === "M"
                  ? 2
                  : r.data.giorniChiusura.substring(2, 3) === "P"
                  ? 3
                  : r.data.giorniChiusura.substring(2, 3) === "T"
                  ? 4
                  : "";
              this.giovedi =
                r.data.giorniChiusura.substring(3, 4) === " "
                  ? 1
                  : r.data.giorniChiusura.substring(3, 4) === "M"
                  ? 2
                  : r.data.giorniChiusura.substring(3, 4) === "P"
                  ? 3
                  : r.data.giorniChiusura.substring(3, 4) === "T"
                  ? 4
                  : "";
              this.venerdi =
                r.data.giorniChiusura.substring(4, 5) === " "
                  ? 1
                  : r.data.giorniChiusura.substring(4, 5) === "M"
                  ? 2
                  : r.data.giorniChiusura.substring(4, 5) === "P"
                  ? 3
                  : r.data.giorniChiusura.substring(4, 5) === "T"
                  ? 4
                  : "";
              this.invioFatture =
                r.data.abilitazioneInvioFatture == 1 ? true : false;
              this.mail1Fatture = r.data.mail1;
              this.mail2Fatture = r.data.mail2;
            }
          });
      })
      .catch(() => {
        this.overlay = false;
        this.erroreInserimento = "";
        this.overlay = false;
        this.dialogErrore = true;
        this.erroreInserimento = this.$t("key506");
      });
    this.overlay = false;
  },
  methods: {
    Indietro() {
      this.$router.push({ path: `/benvenuto` });
    },
    NormalizzaCampi() {
      this.MDMColore = "";
      this.MAMColore = "";
      this.PAPColore = "";
      this.PDPColore = "";
      if (this.MDM === null || this.MDM === undefined) {
        this.MDM = "00:00";
      }
      if (this.MAM === null || this.MAM === undefined) {
        this.MAM = "00:00";
      }
      if (this.PDP === null || this.PDP === undefined) {
        this.PDP = "00:00";
      }
      if (this.PAP === null || this.PAP === undefined) {
        this.PAP = "00:00";
      }

      this.Validate();
    },
    Validate() {
      this.overlay = true;
      this.Errore = "";
      if (
        this.MDM === "00:00" &&
        this.MAM === "00:00" &&
        this.PDP === "00:00" &&
        this.PAP === "00:00"
      ) {
        this.Errore +=
          "- E' necessario specificare almeno una tra la fascia oraria di apertura del mattino e quella del pomeriggio <br>";
        this.MDMColore = "red lighten-4";
        this.MAMColore = "red lighten-4";
        this.PDPColore = "red lighten-4";
        this.PAPColore = "red lighten-4";
      }
      var regexNum = /[^0-99]/g;
      var resultMDM = this.MDM.replace(":", "").match(regexNum);
      var resultMAM = this.MAM.replace(":", "").match(regexNum);
      var resultPDP = this.PDP.replace(":", "").match(regexNum);
      var resultPAP = this.PAP.replace(":", "").match(regexNum);

      if (resultMDM != null) {
        this.Errore += this.$t("key585");
        this.MDMColore = "red lighten-4";
      }
      if (resultMAM != null) {
        this.Errore += this.$t("key586");
        this.MAMColore = "red lighten-4";
      }
      if (resultPDP != null) {
        this.Errore += this.$t("key587");
        this.PDPColore = "red lighten-4";
      }
      if (resultPAP != null) {
        this.Errore += this.$t("key588");
        this.PAPColore = "red lighten-4";
      }
      if (
        this.MDM.trim().length != 5 ||
        this.MDM.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore += this.$t("key471");
        this.MDMColore = "red lighten-4";
      } else if (this.MDM !== "00:00") {
        if (!this.MDM.includes(":")) {
          this.Errore += this.$t("key471");
          this.MDMColore = "red lighten-4";
        }
        if (this.MDM[2] != ":") {
          this.Errore += this.$t("key471");
          this.MDMColore = "red lighten-4";
        }
        if (
          this.MAM !== "00:00" &&
          this.MDM.substring(0, 2) > this.MAM.substring(0, 2)
        ) {
          this.Errore += this.$t("key473");
          this.MDMColore = "red lighten-4";
        }
        if (
          Number(this.MDM.substring(0, 2)) > 13 ||
          (Number(this.MDM.substring(0, 2)) === 13 &&
            Number(this.MDM.substring(3, 5)) > 0)
        ) {
          this.Errore += this.$t("key474");
          this.MDMColore = "red lighten-4";
        }
        if (
          Number(this.MDM.substring(0, 2)) < 6 ||
          (Number(this.MDM.substring(0, 2)) === 6 &&
            Number(this.MDM.substring(3, 5)) < 30)
        ) {
          this.Errore += this.$t("key475");
          this.MDMColore = "red lighten-4";
        }
        if (
          this.MAM !== "00:00" &&
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        ) {
          this.Errore += this.$t("key473");
          this.MDMColore = "red lighten-4";
        }
        if (this.MDM == this.MAM) {
          this.Errore += this.$t("key477");
          this.MDMColore = "red lighten-4";
        }
        if (Number(this.MDM.substring(3, 5)) > 59) {
          this.Errore += this.$t("key478");
          this.MDMColore = "red lighten-4";
        }
      }
      if (
        this.MAM.trim().length != 5 ||
        this.MAM.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore += this.$t("key482");
        this.MAMColore = "red lighten-4";
      } else if (this.MAM !== "00:00") {
        if (!this.MAM.includes(":")) {
          this.Errore += this.$t("key482");
          this.MAMColore = "red lighten-4";
        }
        if (
          Number(this.MAM.substring(0, 2)) > 13 ||
          (Number(this.MAM.substring(0, 2)) === 13 &&
            Number(this.MAM.substring(3, 5)) > 0)
        ) {
          this.Errore += this.$t("key474");
          this.MAMColore = "red lighten-4";
        }
        if (
          Number(this.MDM.substring(0, 2)) < 6 ||
          (Number(this.MDM.substring(0, 2)) === 6 &&
            Number(this.MDM.substring(3, 5)) < 30)
        ) {
          this.Errore += this.$t("key475");
          this.MAMColore = "red lighten-4";
        }
        if (
          this.MDM !== "00:00" &&
          this.MDM.substring(0, 2) > this.MAM.substring(0, 2)
        ) {
          this.Errore += this.$t("key485");
          this.MAMColore = "red lighten-4";
        }
        if (
          this.MDM !== "00:00" &&
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        ) {
          this.Errore += this.$t("key485");
          this.MAMColore = "red lighten-4";
        }
        if (this.MDM == this.MAM) {
          this.Errore += this.$t("key487");
          this.MAMColore = "red lighten-4";
        }
        if (Number(this.MAM.substring(3, 5)) > 59) {
          this.Errore += this.$t("key488");
          this.MAMColore = "red lighten-4";
        }
      }

      if (
        this.PDP.trim().length != 5 ||
        this.PDP.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore += this.$t("key490");
        this.PDPColore = "red lighten-4";
      } else if (this.PDP !== "00:00") {
        if (!this.PDP.includes(":")) {
          this.Errore += this.$t("key491");
          this.PDPColore = "red lighten-4";
        }
        if (
          Number(this.PDP.substring(0, 2)) > 19 ||
          (Number(this.PDP.substring(0, 2)) === 19 &&
            Number(this.PDP.substring(3, 5)) > 30)
        ) {
          this.Errore += this.$t("key492");
          this.PDPColore = "red lighten-4";
        }
        if (
          Number(this.PDP.substring(0, 2)) < 13 ||
          (Number(this.PDP.substring(0, 2)) === 13 &&
            Number(this.PDP.substring(3, 5)) < 1)
        ) {
          this.Errore += this.$t("key493");
          this.PDPColore = "red lighten-4";
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) > this.PAP.substring(0, 2)
        ) {
          this.Errore += this.$t("key494");
          this.PDPColore = "red lighten-4";
        }
        if (Number(this.PDP.substring(3, 5)) > 59) {
          this.Errore += this.$t("key495");
          this.PDPColore = "red lighten-4";
        }
        if (this.PDP == this.PAP) {
          this.Errore += this.$t("key496");
          this.PDPColore = "red lighten-4";
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        ) {
          this.Errore += this.$t("key494");
          this.PDPColore = "red lighten-4";
        }
      }
      if (
        this.PAP.trim().length != 5 ||
        this.PAP.trim().replaceAll(":", "").length != 4
      ) {
        this.Errore += this.$t("key495");
        this.PAPColore = "red lighten-4";
      } else if (this.PAP !== "00:00") {
        if (!this.PAP.includes(":")) {
          this.Errore += this.$t("key495");
          this.PAPColore = "red lighten-4";
        }
        if (
          Number(this.PAP.substring(0, 2)) > 19 ||
          (Number(this.PAP.substring(0, 2)) === 19 &&
            Number(this.PAP.substring(3, 5)) > 30)
        ) {
          this.Errore += this.$t("key492");
          this.PAPColore = "red lighten-4";
        }
        if (
          Number(this.PAP.substring(0, 2)) < 13 ||
          (Number(this.PAP.substring(0, 2)) === 13 &&
            Number(this.PAP.substring(3, 5)) < 1)
        ) {
          this.Errore += this.$t("key493");
          this.PAPColore = "red lighten-4";
        }
        if (
          this.PAP !== "00:00" &&
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        ) {
          this.Errore += this.$t("key503");
          this.PAPColore = "red lighten-4";
        }
        if (Number(this.PAP.substring(3, 5)) > 59) {
          this.Errore += this.$t("key495");
          this.PAPColore = "red lighten-4";
        }
        if (this.PDP == this.PAP) {
          this.Errore += this.$t("key505");
          this.PAPColore = "red lighten-4";
        }
        if (
          this.PDP !== "00:00" &&
          this.PDP.substring(0, 2) > this.PAP.substring(0, 2)
        ) {
          this.Errore += this.$t("key503");
          this.PAPColore = "red lighten-4";
        }
      }
      if (this.MAM === "00:00" && this.MDM !== "00:00") {
        this.Errore += this.$t("key479");
        this.MAMColore = "red lighten-4";
      } else {
        if (this.MAM !== "00:00" && this.MDM === "00:00") {
          this.Errore += this.$t("key480");
          this.MDMColore = "red lighten-4";
        }
      }
      if (this.PDP === "00:00" && this.PAP !== "00:00") {
        this.Errore += this.$t("key581");
        this.PDPColore = "red lighten-4";
      } else {
        if (this.PDP !== "00:00" && this.PAP === "00:00") {
          this.Errore += this.$t("key582");
          this.PAPColore = "red lighten-4";
        }
      }
      if (this.Errore !== "") {
        this.overlay = false;
        this.erroreValidazione = true;
      } else {
        if (this.MDM === "00:00" && this.MAM === "00:00") {
          if (this.lunedi != 4) this.lunedi = 2;
          if (this.martedi != 4) this.martedi = 2;
          if (this.mercoledi != 4) this.mercoledi = 2;
          if (this.giovedi != 4) this.giovedi = 2;
          if (this.venerdi) this.venerdi = 2;
        } else if (this.PDP === "00:00" && this.PAP === "00:00") {
          if (this.lunedi != 4) this.lunedi = 3;
          if (this.martedi != 4) this.martedi = 3;
          if (this.mercoledi != 4) this.mercoledi = 3;
          if (this.giovedi != 4) this.giovedi = 3;
          if (this.venerdi != 4) this.venerdi = 3;
        }
      }
      if (this.invioFatture == false) {
        this.mail1Fatture = "";
        this.mail2Fatture = "";
      }
      if (this.mail1Fatture === null || this.mail1Fatture === undefined)
        this.mail1Fatture = "";
      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      if (this.mail1Fatture.trim() !== "") {
        var resultMail = this.mail1Fatture.trim().match(regexEmail);
        if (resultMail === null) {
          this.Errore += "- La mail 1 per l'invio delle fatture non è valida";
        }
      }
      if (this.mail2Fatture === null || this.mail2Fatture === undefined)
        this.mail2Fatture = "";
      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      if (this.mail2Fatture.trim() !== "") {
        var resultMail = this.mail2Fatture.trim().match(regexEmail);
        if (resultMail === null) {
          this.Errore += "- La mail 2 per l'invio delle fatture non è valida";
        }
      }
      if (this.mail1Fatture == null || this.mail1Fatture == undefined)
        this.mail1Fatture = "";
      if (this.mail2Fatture == null || this.mail2Fatture == undefined)
        this.mail2Fatture = "";
      if (
        this.invioFatture &&
        this.mail1Fatture.trim() == "" &&
        this.mail2Fatture.trim() == ""
      )
        this.Errore +=
          "- E' necessario specificare almeno una mail per l'invio delle fatture.";
      if (this.perIdentificativo === 1 || this.perIdentificativo === "1") {
        this.tipoSalvataggio = 0;
      }
      if (this.Errore === "") {
        this.giochi =
          this.CalcolaGiorniChiusura(this.lunedi) +
          this.CalcolaGiorniChiusura(this.martedi) +
          this.CalcolaGiorniChiusura(this.mercoledi) +
          this.CalcolaGiorniChiusura(this.giovedi) +
          this.CalcolaGiorniChiusura(this.venerdi);
        this.Submit();
      } else {
        this.erroreInserimentoRecord = "";
        this.overlay = false;
        this.dialogErroreInserimento = true;
        this.erroreInserimentoRecord = this.Errore;
      }
    },
    Submit() {
      if (!this.showSalvAut) {
        this.tipoSalvataggio = 0;
      }
      if (!this.mostraSelezioneLogo) {
        this.stampaLogo = false;
      }
      if (!this.menuBol) {
        this.forzaEspresso = false;
        this.forzaInvioTracking = false;
      }
      var json = {
        GiorniChiusura: this.giochi,
        OraAperturaMattino: this.MDM,
        OraAperturaPomeriggio: this.PDP,
        OraChiusuraPomeriggio: this.PAP,
        OraChiusuraMattino: this.MAM,
        AbilitazioneInvioFatture: this.invioFatture ? 1 : 0,
        Mail1: this.mail1Fatture,
        Mail2: this.mail2Fatture,
        SalvataggioAutomaticoRubrica: this.tipoSalvataggio,
        LogoSuEtichetta: this.stampaLogo ? 1 : 0,
        ForzaEspresso: this.forzaEspresso ? 1 : 0,
        ForzaInvioTracking: this.forzaInvioTracking ? 1 : 0,
        LastAction: "",
      };
      this.axios
        .post(this.$store.state.servicePath + "/api/ArGestioneUtenzas", json)
        .then((res) => {
          if (res.data == "ok") {
            this.dialogok = true;
            this.inserimentook = this.$t("key602");
            this.overlay = false;
          } else {
            this.overlay = false;
            this.dialogErroreInserimento = true;
            this.erroreInserimentoRecord = this.$t("key603");
          }
        })
        .catch(() => {
          this.overlay = false;
          this.dialogErroreInserimento = true;
          this.erroreInserimentoRecord = this.$t("key603");
        });
    },
    logout() {
      this.dialogErrore = false;
      // window.location.href = "/#/";
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    isIntegerDate(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 58)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    CheckJWT() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/checkJwt", {
          route: this.$route.path,
        })
        .then((res) => {
          if (res.data.status == "ko") {
            this.$router.push({ path: `/errore` });
          }
        })
        .catch(() => {
          this.$router.push({ path: `/errore` });
        });
    },
    CalcolaGiorniChiusura(giorno) {
      if (giorno === 1) {
        return " ";
      } else if (giorno === 2) {
        return "M";
      } else if (giorno === 3) {
        return "P";
      } else if (giorno === 4) {
        return "T";
      }
    },
    CheckMDM() {
      this.ErroreOrario = "";
      var erroreMDM = "";
      var vuoto = false;
      var vuotoMAM = false;
      if (this.MDM === null || this.MDM === "" || this.MDM === "00:00") {
        erroreMDM += "";
        vuoto = true;
      }
      if (this.MAM === "" || this.MAM === "00:00") {
        vuotoMAM = true;
      }
      var regexNum = /[^0-99]/g;
      var resultMDM = this.MDM.replace(":", "").match(regexNum);
      if (resultMDM != null) {
        erroreMDM += this.$t("key585");
      }
      if (
        this.MDM.trim().length != 5 ||
        this.MDM.trim().replaceAll(":", "").length != 4
      )
        erroreMDM += this.$t("key471");
      else {
        if (!this.MDM.includes(":")) erroreMDM += this.$t("key471");
        if (this.MDM[2] != ":") erroreMDM += this.$t("key471");
        if (this.MDM.substring(0, 2) > this.MAM.substring(0, 2))
          erroreMDM += this.$t("key473");
        if (
          Number(this.MDM.substring(0, 2)) > 13 ||
          (Number(this.MDM.substring(0, 2)) === 13 &&
            Number(this.MDM.substring(3, 5)) > 0)
        )
          erroreMDM += this.$t("key474");
        if (
          Number(this.MDM.substring(0, 2)) < 6 ||
          (Number(this.MDM.substring(0, 2)) === 6 &&
            Number(this.MDM.substring(3, 5)) < 30)
        ) {
          erroreMDM += this.$t("key475");
        }
        if (
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        )
          erroreMDM += this.$t("key473");
        if (this.MDM == this.MAM) erroreMDM += this.$t("key477");
        if (Number(this.MDM.substring(3, 5)) > 59)
          erroreMDM += this.$t("key471");
      }
      if (erroreMDM != "" && vuoto === false && vuotoMAM === false) {
        this.DialogErroreOrario = true;
        this.ErroreOrario = erroreMDM;
      }
      if (vuoto) {
        this.MDM = "00:00";
      }
    },
    CheckMattino() {
      this.ErroreOrarioMattino = "";
      var erroreMAM = "";
      if (
        (this.MAM === null || this.MAM === "" || this.MAM === "00:00") &&
        this.MDM !== "" &&
        this.MDM !== "00:00"
      ) {
        erroreMAM += this.$t("key479");
        this.DialogErroreOrarioMAM = true;
        this.ErroreOrarioMattino = erroreMAM;
      } else {
        if (
          this.MAM !== "" &&
          this.MAM !== "00:00" &&
          (this.MDM === "00:00" || this.MDM === "")
        ) {
          erroreMAM += this.$t("key480");
          this.DialogErroreOrario = true;
          this.ErroreOrarioMattino = erroreMAM;
        }
      }
    },
    CheckMAM() {
      this.ErroreOrario = "";
      var erroreMAM = "";
      var vuoto = false;
      var vuotoMDM = false;
      if (this.MAM === null || this.MAM === "" || this.MAM === "00:00") {
        erroreMAM += this.$t("key481");
        vuoto = true;
        this.MAM = "00:00";
      }
      var regexNum = /[^0-99]/g;
      var resultMAM = this.MAM.replace(":", "").match(regexNum);
      if (resultMAM != null) {
        erroreMAM += this.$t("key586");
      }
      if (this.MDM === "" || this.MDM === "00:00") {
        vuotoMDM = true;
      }
      if (
        this.MAM.trim().length != 5 ||
        this.MAM.trim().replaceAll(":", "").length != 4
      )
        erroreMAM += this.$t("key482");
      else {
        if (!this.MAM.includes(":")) erroreMAM += this.$t("key482");
        if (
          Number(this.MAM.substring(0, 2)) > 13 ||
          (Number(this.MAM.substring(0, 2)) === 13 &&
            Number(this.MAM.substring(3, 5)) > 0)
        )
          erroreMAM += this.$t("key474");
        if (
          Number(this.MAM.substring(0, 2)) < 6 ||
          (Number(this.MAM.substring(0, 2)) === 6 &&
            Number(this.MAM.substring(3, 5)) < 30)
        )
          erroreMAM += this.$t("key484");
        if (this.MDM.substring(0, 2) > this.MAM.substring(0, 2))
          erroreMAM += this.$t("key485");
        if (
          this.MDM.substring(0, 2) == this.MAM.substring(0, 2) &&
          Number(this.MDM.substring(3, 5)) > Number(this.MAM.substring(3, 5))
        )
          erroreMAM += this.$t("key485");
        if (this.MDM == this.MAM) erroreMAM += this.$t("key487");
        if (Number(this.MAM.substring(3, 5)) > 59)
          erroreMAM += this.$t("key488");
      }
      if (erroreMAM != "" && vuoto === false && vuotoMDM === false) {
        this.DialogErroreOrarioMAM = true;
        this.ErroreOrario = erroreMAM;
      }
    },
    CheckPDP() {
      this.ErroreOrario = "";
      var errorePDP = "";
      var vuoto = false;
      if (this.PDP === null || this.PDP === "" || this.PDP === "00:00") {
        vuoto = true;
        this.PDP = "00:00";
      }
      if (this.PDP == null || this.PDP == "") errorePDP += this.$t("key489");
      var regexNum = /[^0-99]/g;
      var resultPDP = this.PDP.replace(":", "").match(regexNum);
      if (resultPDP != null) {
        errorePDP += this.$t("key587");
      }
      if (
        this.PDP.trim().length != 5 ||
        this.PDP.trim().replaceAll(":", "").length != 4
      )
        errorePDP += this.$t("key490");
      else {
        if (!this.PDP.includes(":")) errorePDP += this.$t("key491");
        if (
          Number(this.PDP.substring(0, 2)) > 19 ||
          (Number(this.PDP.substring(0, 2)) === 19 &&
            Number(this.PDP.substring(3, 5)) > 30)
        )
          errorePDP += this.$t("key491");
        if (
          Number(this.PDP.substring(0, 2)) < 13 ||
          (Number(this.PDP.substring(0, 2)) === 13 &&
            Number(this.PDP.substring(3, 5)) < 1)
        )
          errorePDP += this.$t("key493");
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePDP += this.$t("key494");
        if (Number(this.PDP.substring(3, 5)) > 59)
          errorePDP += this.$t("key495");
        if (this.PDP == this.PAP) errorePDP += this.$t("key496");
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePDP += this.$t("key494");
        if (
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        )
          errorePDP += this.$t("key497");
      }
      if (errorePDP != "" && vuoto === false) {
        this.DialogErroreOrarioPDP = true;
        this.ErroreOrario = errorePDP;
      }
    },
    CheckPAP() {
      this.ErroreOrario = "";
      var errorePAP = "";
      var vuoto = false;
      if (this.PAP === null || this.PAP === "" || this.PAP === "00:00") {
        vuoto = true;
        this.PAP = "00:00";
      }
      if (this.PAP == null || this.PAP == "") errorePAP += this.$t("key498");
      var regexNum = /[^0-99]/g;
      var resultPDP = this.PDP.replace(":", "").match(regexNum);
      if (resultPDP != null) {
        errorePDP += this.$t("key587");
      }
      if (
        this.PDP.trim().length != 5 ||
        this.PDP.trim().replaceAll(":", "").length != 4
      )
        errorePAP += this.$t("key495");
      else {
        if (!this.PAP.includes(":")) errorePAP += this.$t("key495");
        if (
          Number(this.PAP.substring(0, 2)) > 19 ||
          (Number(this.PAP.substring(0, 2)) === 19 &&
            Number(this.PAP.substring(3, 5)) > 30)
        )
          errorePAP += this.$t("key501");
        if (
          Number(this.PAP.substring(0, 2)) < 13 ||
          (Number(this.PAP.substring(0, 2)) === 13 &&
            Number(this.PAP.substring(3, 5)) < 1)
        )
          errorePAP += this.$t("key502");
        if (
          this.PDP.substring(0, 2) == this.PAP.substring(0, 2) &&
          Number(this.PDP.substring(3, 5)) > Number(this.PAP.substring(3, 5))
        )
          errorePAP += this.$t("key503");
        if (Number(this.PAP.substring(3, 5)) > 59)
          errorePAP += this.$t("key495");
        if (this.PDP == this.PAP) errorePAP += this.$t("key505");
        if (this.PDP.substring(0, 2) > this.PAP.substring(0, 2))
          errorePAP += this.$t("key503");
      }
      if (errorePAP != "" && vuoto === false) {
        this.DialogErroreOrarioPAP = true;
        this.ErroreOrario = errorePAP;
      }
    },
    focusNextTick(el) {
      this.$nextTick(() => {
        el.focus();
      });
    },
  },
  watch: {
    MDM: function (value) {
      if (value.length == 2) this.MDM = value + ":";
    },
    MAM: function (value) {
      if (value.length == 2) this.MAM = value + ":";
    },
    PAP: function (value) {
      if (value.length == 2) this.PAP = value + ":";
    },
    PDP: function (value) {
      if (value.length == 2) this.PDP = value + ":";
    },
    "$i18n.locale": function (value) {
      if (value == "it") {
        this.listaSalvataggioAut = ["Mai", "Sempre", "Con conferma"];
      } else {
        this.listaSalvataggioAut = ["Never", "Always", "With confirmation"];
      }
    },
  },
};
</script>

<style>
.v-card__title {
  word-break: break-word !important;
}
</style>