<template>
  <v-layout justify-center>
    <v-dialog v-model="overlay" persistent max-width="600">
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="imgNonTrovata" persistent max-width="400">
      <v-card>
        <v-card-title>
          <b>IMMAGINE NON TROVATA</b>
        </v-card-title>
        <v-card-text>
          L'immagine della rulliera non è presente sui sistemi Arco
          Spedizioni.<br />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errore" persistent max-width="400">
      <v-card>
        <v-card-title>
          <b>ERRORE VISUALIZZAZIONE IMMAGINI</b>
        </v-card-title>
        <v-card-text>
          Non è stato possibile recuperare l'immagine richiesta.<br />
          Si prega di riprovare tra qualche minuto e nel caso non si riuscisse
          contattare l'assistenza.
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="mostraRulliera" persistent max-width="1080px">
      <v-card>
        <v-card-actions>
          <v-btn
            @click="EstraiImmaginiRulliera"
            color="#A0A4A7"
            style="color: white"
            depressed
            >{{ $t("key353") }}</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <v-carousel
            :cycle="false"
            :show-arrows="true"
            height="calc(90vh - 86px)"
            light
            id="rulliereAs"
            style="box-shadow: none"
            :hide-delimiters="false"
          >
            <v-carousel-item
              v-for="(item, i) in foto"
              :key="i"
              style="text-align: center"
            >
              <h3 style="text-align: center; margin: 6px 0">
                {{ i + 1 }}/{{ foto.length }}
              </h3>

              <img
                :src="item.src"
                :alt="item.src"
                style="max-height: calc(90vh - 172px); max-width: 100%"
              />
            </v-carousel-item>
          </v-carousel>

          <!-- <v-carousel
            :cycle="false"
            :show-arrows="true"
            :height="altezzaRulliera"
            light
            id="rulliereAs"
          >
            <v-carousel-item
              v-for="(item, i) in foto"
              :key="i"
              :src="item.src"
              :alt="item.src"
            >
            </v-carousel-item>
          </v-carousel> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      numeroSpedizione: "",
      mostraRulliera: false,
      altezzaRulliera: 0,
      foto: [],
      overlay: true,
      errore: false,
      imgNonTrovata: false,
      spedizioneRiconsegna: "",
      indice: 0
    };
  },
  mounted() {
    this.numeroSpedizione = this.$route.params.numeroSpedizione;
    this.scaricaFotoRulliera(this.numeroSpedizione);
    // this.getRiconsegna(this.numeroSpedizione);
  },
  methods: {
    scaricaFotoRulliera(numsped) {
      this.axios
        .get(
          this.$store.state.servicePath +
            "/api/Pod/Rulliere/Download/" +
            numsped.trim()
        )
        .then((res) => {
          if (res.data.length != 0) {
            this.altezzaRulliera = Number(res.data[0].nomeFile);
            res.data.forEach((element) => {
              if (element.src != null) {
                this.foto.push({
                  src:
                    "data:image/" +
                    element.estensione +
                    ";base64," +
                    element.src,
                });
              }
            });
          }
          if (this.foto.length > 0) {
            this.mostraRulliera = true;
            this.overlay = false;
          } else {
            this.mostraRulliera = false;
            this.altezzaRulliera = 0;
            this.imgNonTrovata = true;
            this.overlay = false;
          }
        })
        .catch(() => {
          this.overlay = false;
          this.errore = true;
        });
    },
    EstraiImmaginiRulliera() {
      this.indice = 0;
      this.foto.forEach((e, i) => {
        setTimeout(() => {
          let imgContent = e.src;
          var encodedUri = encodeURI(imgContent);
          var decodedString = atob(
            imgContent.replace("data:image/jpg;base64,", "")
          );
          var byteCharacters = decodedString;
          var byteNumbers = new Array(byteCharacters.length);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            var blob = new Blob([byteArray], {
              type: "image/jpg",
            });
            navigator.msSaveBlob(
              blob,
              this.numeroSpedizione.split("-->")[0].trim() +
                "_" +
                this.indice +
                ".jpg"
            );
          } else {
            const link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute(
              "download",
              this.numeroSpedizione.split("-->")[0].trim() +
                "_" +
                this.indice +
                ".jpg"
            );

            link.click();
          }
          this.indice += 1;
        }, i * 100);
      });
    },
    // getRiconsegna(numspe) {
    //   this.axios
    //     .get(this.$store.state.servicePath + "/api/ArTrackingDetails/" + numspe)
    //     .then((res) => {
    //       this.numeroSpedizione = res.data.arTracking[0].codiceSpedizione
    //         .split("-->")[0]
    //         .trim();

    //       try {
    //         this.spedizioneRiconsegna = res.data.arTracking[0].codiceSpedizione
    //           .split("-->")[1]
    //           .trim();
    //       } catch (e) {
    //         this.spedizioneRiconsegna = "";
    //       }

    //       var promises = [];
    //       promises.push(this.scaricaFotoRulliera(this.numeroSpedizione));
    //       if (this.spedizioneRiconsegna != "") {
    //         promises.push(this.scaricaFotoRulliera(this.spedizioneRiconsegna));
    //       }

    //       Promise.all(promises)
    //         .then(() => {
    //           if (this.foto.length > 0) {
    //             this.mostraRulliera = true;
    //             this.overlay = false;
    //           } else {
    //             this.mostraRulliera = false;
    //             this.altezzaRulliera = 0;
    //             this.imgNonTrovata = true;
    //             this.overlay = false;
    //           }
    //         })
    //         .catch(() => {
    //           this.overlay = false;
    //           this.errore = true;
    //         });
    //     })
    //     .catch(() => {
    //       this.overlay = false;
    //       this.errore = true;
    //     });
    // },
    // scaricaFotoRulliera(numsped) {
    //   return new Promise((resolve, reject) => {
    //     this.axios
    //       .get(
    //         this.$store.state.servicePath +
    //           "/api/Pod/Rulliere/Download/" +
    //           numsped.trim()
    //       )
    //       .then((res) => {
    //         if (res.data.length != 0) {
    //           this.altezzaRulliera = Number(res.data[0].nomeFile);
    //           res.data.forEach((element) => {
    //             if (element.src != null) {
    //               this.foto.push({
    //                 src:
    //                   "data:image/" +
    //                   element.estensione +
    //                   ";base64," +
    //                   element.src,
    //               });
    //             }
    //           });
    //         }
    //         resolve();
    //       })
    //       .catch(() => {
    //         reject();
    //       });
    //   });
    // },
  },
};
</script>

<style>
#rulliereAs div.v-image__image.v-image__image--cover {
  background-size: contain !important;
}

#rulliereAs > div.v-carousel__controls {
  background: rgba(0, 0, 0, 0);
  /* background: rgba(0, 0, 0, 0.2); */
}

/* #rulliereAs > div.v-carousel__controls > div > button {
  color: white;
} */
</style>