import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index.js'
import MenusList from '../views/MenusList.vue'
import MenusDetail from '../views/MenusDetail.vue'
import GruppiList from '../views/GruppiList.vue'
import GruppiDetail from '../views/GruppiDetail.vue'
import Login from '../views/Login.vue'
import PermessiList from '../views/PermessiList.vue'
import Benvenuto from '../views/Benvenuto.vue'
import PermessiDetail from '../views/PermessiDetail.vue'
import UtentiList from '../views/UtentiList.vue'
import UtentiDetail from '../views/UtentiDetail.vue'
import Tracking from '../views/TrackingList.vue'
import TrackingDetail from '../views/TrackingDetail.vue'
import IconeList from '../views/IconeList.vue'
import IconeDetail from '../views/IconeDetail.vue'
import RubricaList from '../views/RubricaList.vue'
import RubricaDetail from '../views/RubricaDetail.vue'
import Errore from '../views/Errore.vue'
import CambiaPassword from '../views/CambiaPassword.vue'
import CambiaPasswordOk from '../views/CambiaPasswordOk.vue'
import RecuperaPassword from '../views/RecuperaPassword.vue'
import TrackList from '../views/TrackList.vue'
import TrackDetail from '../views/TrackDetail.vue'
import StatiCorrispondentiList from '../views/StatiCorrispondentiList.vue'
import StatiCorrispondentiDetail from '../views/StatiCorrispondentiDetail.vue'
import StatiSpedizioneList from '../views/StatiSpedizioneList.vue'
import StatiSpedizioneDetail from '../views/StatiSpedizioneDetail.vue'
import SediList from '../views/SediList.vue'
import SediDetail from '../views/SediDetail.vue'
import TrackDetailNoUser from '../views/TrackDetailNoUser.vue'
import Ritiro from '../views/Ritiro.vue'
import RitiroAggiunto from '../views/RitiroAggiunto.vue'
import BollettazioneList from '../views/BollettazioneList.vue'
import BollettazioneDetail from '../views/BollettazioneDetail.vue'
import DisposizioniList from '../views/DisposizioniList.vue'
import DisposizioniDetail from '../views/DisposizioniDetail.vue'
import TipoIncassoList from '../views/TipoIncassoList.vue'
import TipoIncassoDetail from '../views/TipoIncassoDetail.vue'
import BollettazioneRiepilogo from '../views/BollettazioneRiepilogo.vue'
import PesiVolumiList from '../views/PesiVolumiList.vue'
import PesiVolumiDetail from '../views/PesiVolumiDetail.vue'
import TraduzioniDetail from '../views/TraduzioniDetail.vue'
import TraduzioniList from '../views/TraduzioniList.vue'
import TracciatiImportList from '../views/TracciatiImportList.vue'
import ResetPassword from '../views/ResetPassword.vue'
import RitiroNew from '../views/RitiroNew.vue'
import DownloadPOD from '../views/DownloadPOD.vue'
import TrackDetailAs400 from '../views/TrackDetailAs400.vue'
import Login_Registrazione from '../views/Login_Registrazione.vue'
import Registrazione from '../views/Registrazione.vue'
import Registrazione2 from '../views/Registrazione2.vue'
import AttivazioneUtente from '../views/AttivazioneUtente.vue'
import RegistrazioneBreve from '../views/RegistrazioneBreve.vue'
import DisattivazioneUtente from '../views/DisattivazioneUtente.vue'
import TrackingListNew from '../views/TrackingListNew.vue'
import Landing from '../views/Landing.vue'
import Download from '../views/Download.vue'
import TrackingRitiriList from '../views/TrackingRitiriList.vue'
import TrackingRitiriDetail from '../views/TrackingRitiriDetail.vue'
import ProblemaTecnico from '../views/ProblemaTecnico.vue'
import NuoviRitiri from '../views/NuoviRitiri.vue'
import DownloadRimessi from '../views/DownloadRimessi.vue'
import NuovoRitiroAggiunto from '../views/NuovoRitiroAggiunto.vue'
import VerificaAttivazione from '../views/VerificaAttivazione.vue'
import NuoviRitiriServizi from '../views/NuoviRitiriServizi.vue'
import NuovaRubricaList from '../views/NuovaRubricaList.vue'
import NuovaRubricaDetail from '../views/NuovaRubricaDetail.vue'
import SvincoloGiacenza from '../views/SvincoloGiacenza.vue'
import PodAs400 from '../views/PodAs400.vue'
import SearchPod from '../views/Pod.vue'
import FirmaAs400 from '../views/FirmaAs400.vue'
import AnomaliaAs400 from '../views/AnomaliaAs400.vue'
import TrackingClienti from '../views/TrackingClienti.vue'
import GestioneUtenza from '../views/GestioneUtenza.vue'
import CodiciMasterList from '../views/CodiciMasterList.vue'
import CodiciMasterDetail from '../views/CodiciMasterDetail.vue'
import PrenotazioneConsegna from '../views/PrenotazioneConsegna.vue'
import PrenotazioneConsegnaStandard from '../views/PrenotazioneConsegnaStandard.vue'
import FattureClienti from '../views/FattureClienti.vue'
import GiacenzeGFF from '../views/GiacenzeGFF.vue'
import Test from '../views/Test.vue'
import CruscottoCliente from '../views/CruscottoCliente.vue'
import ImmaginiPiombi from '../views/ImmaginiPiombi.vue'
import Kpi from '../views/Kpi.vue'
import RulliereAs400 from '../views/RulliereAs400.vue'
import GiacenzeLogistica from '../views/GiacenzeLogistica.vue'
import Movimenti from '../views/Movimenti.vue'
import DocumentiIngresso from '../views/DocumentiIngresso.vue'
import DocumentiUscita from '../views/DocumentiUscita.vue'
// import AggiungiDocumentoDiUscitaDetail from '../views/AggiungiDocumentoDiUscitaDetail.vue'
// import AggiungiDocumentoDiUscitaList from '../views/AggiungiDocumentoDiUscitaList.vue'
import GestioneAvvisiList from '../views/GestioneAvvisiList.vue'
import GestioneAvvisiDetail from '../views/GestioneAvvisiDetail.vue'
import MostraAvvisiList from '../views/MostraAvvisiList.vue'
import FotoContenziosiAs400 from '../views/FotoContenziosiAs400.vue'
import RulliereClienti from '../views/RulliereClienti.vue'

Vue.use(Router)

let router = new Router({

  routes: [
    //FATTO
    {
      path: '/',
      name: 'Login_Registrazione',
      component: Login_Registrazione
    },
    //FATTO
    {
      path: '/Piombi/:anno/:mese/:giorno/:image',
      name: 'ImmaginiPiombi',
      component: ImmaginiPiombi
    },
    //FATTO
    {
      path: '/fatture',
      name: 'FattureClienti',
      component: FattureClienti
    },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/prencon/PrenotazioneConsegna',
    //   name: 'PrenotazioneConsegna',
    //   component: PrenotazioneConsegna
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/prencon/PrenotazioneConsegnaStandard',
    //   name: 'PrenotazioneConsegnaStandard',
    //   component: PrenotazioneConsegnaStandard
    // },
    //FATTO
    {
      path: '/CodiciMaster/:id/:email/:codice',
      name: 'CodiciMasterDetail',
      component: CodiciMasterDetail
    },
    //FATTO
    {
      path: '/CodiciMaster',
      name: 'CodiciMasterList',
      component: CodiciMasterList
    },
    //FATTO
    {
      path: '/GestioneUtenza',
      name: 'GestioneUtenza',
      component: GestioneUtenza
    },
    //FATTO
    {
      path: '/tracking/:CodiceCliente/:Nddt/:data',
      name: 'TrackingClienti',
      component: TrackingClienti
    },
    //FATTO
    {
      path: '/AnomaliaAs400/:numeroSpedizione/:numeroLista',
      name: 'AnomaliaAs400',
      component: AnomaliaAs400
    },
    //FATTO
    {
      path: '/FirmaAs400/:numeroSpedizione',
      name: 'FirmaAs400',
      component: FirmaAs400
    },
    //FATTO
    {
      path: '/PodAs400/:numeroSpedizione',
      name: 'PodAs400',
      component: PodAs400
    },
    {
      path: '/searchPod/:jwt/:numeroSpedizione',
      name: 'searchPod',
      component: SearchPod
    },
    //FATTO
    {
      path: '/SvincoloGiacenza/:parametro',
      name: 'SvincoloGiacenza',
      component: SvincoloGiacenza
    },
    //FATTO
    // {
    //   path: '/NuoviRitiriServizi', //NON UTILIZZATO, lASCIARE COMMENTATO
    //   name: 'NuoviRitiriServizi',
    //   component: NuoviRitiriServizi
    // },
    //FATTO
    {
      path: '/VerificaAttivazione',
      name: 'VerificaAttivazione',
      component: VerificaAttivazione
    },
    //FATTO
    {
      path: '/ProblemaTecnico',
      name: 'ProblemaTecnico',
      component: ProblemaTecnico
    },
    //FATTO
    {
      path: '/NuoviRitiri',
      name: 'NuoviRitiri',
      component: NuoviRitiri
    },
    //FATTO
    {
      path: '/DownloadRimessi',
      name: 'DownloadRimessi',
      component: DownloadRimessi
    },
    //FATTO
    {
      path: '/Download',
      name: 'Download',
      component: Download
    },
    //FATTO
    {
      path: '/landing/:mailInvio/:codice/:idUtente/:idGruppo',
      name: 'Landing',
      component: Landing
    },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/TrackingNew',
    //   name: 'Tracking',
    //   component: Tracking
    // },
    //FATTO
    {
      path: '/AttivazioneUtente/:id/:hash',
      name: 'AttivazioneUtente',
      component: AttivazioneUtente
    },
    //FATTO
    {
      path: '/DisattivazioneUtente/:id/:hash',
      name: 'DisattivazioneUtente',
      component: DisattivazioneUtente
    },
    //FATTO
    {
      path: '/RegistrazioneBreve/:codCliente/:email',
      name: 'RegistrazioneBreve',
      component: RegistrazioneBreve
    },
    // {
    //   path: '/LoginRegistrazione', //NON UTILIZZATO, lASCIARE COMMENTATO
    //   name: 'Login_Registrazione',
    //   component: Login_Registrazione
    // },
    //FATTO
    {
      path: '/registrazione',
      name: 'Registrazione',
      component: Registrazione
    },
    //FATTO
    {
      path: '/registrazione2/:codCliente/:email/:idUtente/:idGruppo',
      name: 'Registrazione2',
      component: Registrazione2
    },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/menus',
    //   name: 'MenusList',
    //   component: MenusList
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/menus/:id',
    //   name: 'MenusDetail',
    //   component: MenusDetail
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/gruppi/:id',
    //   name: 'GruppiDetail',
    //   component: GruppiDetail
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/gruppi',
    //   name: 'GruppiList',
    //   component: GruppiList
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/permessi',
    //   name: 'PermessiList',
    //   component: PermessiList
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/permessi/:id/:tipo',
    //   name: 'PermessiDetail',
    //   component: PermessiDetail
    // },
    //FATTO
    {
      path: '/benvenuto',
      name: 'Benvenuto',
      component: Benvenuto
    },
    //FATTO
    {
      path: '/utenti',
      name: 'UtentiList',
      component: UtentiList
    },
    //FATTO
    {
      path: '/utenti/:id',
      name: 'UtentiDetail',
      component: UtentiDetail
    },
    //FATTO
    {
      path: '/tracking',
      name: 'TrackingListNew',
      component: TrackingListNew
    },
    //FATTO
    {
      path: '/tracking/:Nservizio',
      name: 'TrackingDetail',
      component: TrackingDetail
    },
    //FATTO
    {
      path: '/downloadpod',
      name: 'DownloadPOD',
      component: DownloadPOD
    },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/icone',
    //   name: 'IconeList',
    //   component: IconeList
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/icone/:id',
    //   name: 'IconeDetail',
    //   component: IconeDetail
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/rubrica',
    //   name: 'RubricaList',
    //   component: RubricaList
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/rubrica/:id/:codiceAzienda',
    //   name: 'RubricaDetail',
    //   component: RubricaDetail
    // },
    //FATTO
    {
      path: '/NuovaRubrica',
      name: 'NuovaRubricaList',
      component: NuovaRubricaList
    },
    //FATTO
    {
      path: '/NuovaRubrica/:id/:codiceAzienda',
      name: 'NuovaRubricaDetail',
      component: NuovaRubricaDetail
    },
    //FATTO
    {
      path: '/errore',
      name: 'Errore',
      component: Errore
    },
    //FATTO
    {
      path: '/cambiapassword',
      name: 'CambiaPassword',
      component: CambiaPassword
    },
    //FATTO
    {
      path: '/cambiapasswordok',
      name: 'CambiaPasswordOk',
      component: CambiaPasswordOk
    },
    //FATTO
    {
      path: '/recuperapassword',
      name: 'RecuperaPassword',
      component: RecuperaPassword
    },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/track/:codice/:sha',
    //   name: 'TrackList',
    //   component: TrackList
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/track/:codice/:sha/:Nservizio',
    //   name: 'TrackDetail',
    //   component: TrackDetail
    // },
    //FATTO
    {
      path: '/staticorrispondenti',
      name: 'StatiCorrispondentiList',
      component: StatiCorrispondentiList
    },
    //FATTO
    {
      path: '/staticorrispondenti/:codiceCorris/:statusCorris',
      name: 'StatiCorrispondentiDetail',
      component: StatiCorrispondentiDetail
    },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/statispedizione',
    //   name: 'StatiSpedizioneList',
    //   component: StatiSpedizioneList
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/statispedizione/:id',
    //   name: 'StatiSpedizioneDetail',
    //   component: StatiSpedizioneDetail
    // },
    //FATTO
    {
      path: '/sedi',
      name: 'SediList',
      component: SediList
    },
    //FATTO
    {
      path: '/sedi/:codice',
      name: 'SediDetail',
      component: SediDetail
    },
    //  FATTA
    {
      path: '/trackingDetail/:Nservizio',
      name: 'TrackDetailNoUser',
      component: TrackDetailNoUser
    },
    //  FATTA - DA TESTARE
    {
      path: '/trackingDetails/:codiceCliente/:Nddt/:data',
      name: 'TrackDetailAs400',
      component: TrackDetailAs400
    },
    // {
    //   path: '/Ritiro', //NON UTILIZZATO, lASCIARE COMMENTATO
    //   name: 'Ritiro',
    //   component: Ritiro
    // },
    // {
    //   path: '/ritironew', //NON UTILIZZATO, lASCIARE COMMENTATO
    //   name: 'RitiroNew',
    //   component: RitiroNew
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/RitiroAggiunto/:id',
    //   name: 'RitiroAggiunto',
    //   component: RitiroAggiunto
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/NuovoRitiroAggiunto/:id',
    //   name: 'NuovoRitiroAggiunto',
    //   component: NuovoRitiroAggiunto
    // },
    //  FATTA - DA TESTARE
    {
      path: '/Bollettazione',
      name: 'BollettazioneList',
      component: BollettazioneList
    },
    //  FATTA - DA TESTARE
    {
      path: '/Bollettazione/:codiceSpedizione/:stato',
      name: 'BollettazioneDetail',
      component: BollettazioneDetail
    },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/Disposizioni',
    //   name: 'DisposizioniList',
    //   component: DisposizioniList
    // },
    // {
    //   path: '/Disposizioni/:id',
    //   name: 'DisposizioniDetail',
    //   component: DisposizioniDetail
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/Incasso',
    //   name: 'TipoIncassoList',
    //   component: TipoIncassoList
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/Incasso/:id',
    //   name: 'TipoIncassoDetail',
    //   component: TipoIncassoDetail
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/Bollettazione/Riepilogo/:codiceSpedizione',
    //   name: 'BollettazioneRiepilogo',
    //   component: BollettazioneRiepilogo
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/PesiVolumi',
    //   name: 'PesiVolumiList',
    //   component: PesiVolumiList
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/PesiVolumi/:Nservizio',
    //   name: 'PesiVolumiDetail',
    //   component: PesiVolumiDetail
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/Traduzioni',
    //   name: 'TraduzioniList',
    //   component: TraduzioniList
    // },
    // NON USATA - DA AGGIORNARE
    // {
    //   path: '/Traduzioni/:codice/:lingua',
    //   name: 'TraduzioniDetail',
    //   component: TraduzioniDetail
    // },
    // {
    //   path: '/Tracciati', //NON UTILIZZATO, lASCIARE COMMENTATO
    //   name: 'TracciatiImportList',
    //   component: TracciatiImportList
    // },
    //FATTO
    {
      path: '/Reset/:id/:p',
      name: 'ResetPassword',
      component: ResetPassword
    },
    // FATTO
    {
      path: '/trackingritirilist',
      name: 'TrackingRitiriList',
      component: TrackingRitiriList
    },
    // FATTO
    {
      path: '/trackingritiridetail/:numrit',
      name: 'TrackingRitiriDetail',
      component: TrackingRitiriDetail
    },
    //FATTO DA TESTARE
    {
      path: '/giacenzeGFF/:giacenza',
      name: 'GiacenzeGFF',
      component: GiacenzeGFF
    },
    //FATTO
    {
      path: '/test/',
      name: 'Test',
      component: Test
    },
    //FATTO
    {
      path: '/cruscotto',
      name: 'CruscottoCliente',
      component: CruscottoCliente
    },
    //FATTO
    {
      path: '/kpi',
      name: 'Kpi',
      component: Kpi
    },
    //FATTO
    {
      path: '/RulliereAs400/:numeroSpedizione',
      name: 'RulliereAs400',
      component: RulliereAs400
    },
    //FATTO
    {
      path: '/esistenze-magazzino',
      name: 'GiacenzeLogistica',
      component: GiacenzeLogistica,
      meta: { logistica: true },
    },
    //FATTO
    {
      path: '/movimenti',
      name: 'Movimenti',
      component: Movimenti,
      meta: { logistica: true },
    },
    //FATTO
    {
      path: '/documenti-ingresso',
      name: 'DocumentiIngresso',
      component: DocumentiIngresso,
      meta: { logistica: true },
    },
    //FATTO
    {
      path: '/documenti-uscita',
      name: 'DocumentiUscita',
      component: DocumentiUscita,
      meta: { logistica: true },
    },
    //FATTO
    // {
    //   path: '/aggiungi-documenti-uscita/:id',
    //   name: 'AggiungiDocumentoDiUscitaDetail',
    //   component: AggiungiDocumentoDiUscitaDetail,
    //   meta: { logistica: true },
    // },
    // {
    //   path: '/aggiungi-documenti-uscita',
    //   name: 'AggiungiDocumentoDiUscitaList',
    //   component: AggiungiDocumentoDiUscitaList,
    //    meta: { logistica: true },
    // },
    {
      path: '/gestione-avvisi',
      name: 'GestioneAvvisiList',
      component: GestioneAvvisiList
    },
    {
      path: '/gestione-avvisi/:id',
      name: 'GestioneAvvisiDetail',
      component: GestioneAvvisiDetail
    },
    {
      path: '/avvisi',
      name: 'MostraAvvisiList',
      component: MostraAvvisiList
    },
    {
      path: '/Contenziosi/:anno/:mese/:giorno/:numeroSped',
      name: 'FotoContenziosiAs400',
      component: FotoContenziosiAs400
    },
    //FATTO
    {
      path: '/FotoRulliera/:codiceClienteSpedizione',
      name: 'RulliereClienti',
      component: RulliereClienti
    },
  ]
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
