<template>
  <v-container>
    <v-layout justify-center>
      <v-dialog v-model="errorDialog" persistent max-width="600">
        <v-card>
          <v-card-title class="headline">{{ formTitle }}</v-card-title>
          <v-card-text v-html="errore"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!redirect"
              large
              color="#A0A4A7"
              style="color: white"
              depressed
              @click="
                errorDialog = false;
                redirect = false;
              "
              >OK</v-btn
            >
            <v-btn
              v-if="redirect"
              large
              color="primary"
              @click="$router.push('/')"
              depressed
              >TORNA AL LOGIN</v-btn
            >
            <v-btn
              v-if="!redirect"
              large
              color="primary"
              @click="$router.push('/recuperapassword')"
              depressed
              >RECUPERA PASSWORD</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout>
      <v-flex xs12 sm12 style="padding-left: 20px">
        <v-card>
          <v-card-title style="font-size: 1.5em">{{
            $t("key356")
          }}</v-card-title>
          <v-form ref="abilita">
            <v-card-text>
              <v-flex sm12>
                <v-text-field
                  v-model="codice"
                  label="Codice Cliente"
                  readonly
                />
              </v-flex>
              <v-flex sm12>
                <v-text-field v-model="mailDupe" label="Email" readonly />
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-btn
                large
                style="box-shadow: none; color: white"
                color="primary"
                @click="Abilita"
                >CONFERMA</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
      <!-- </v-layout>
    <v-layout style="padding-top: 20px"> -->
      <v-flex xs12 sm12 style="padding-left: 20px">
        <v-card>
          <v-card-title style="font-size: 1.5em">{{
            $t("key357")
          }}</v-card-title>
          <v-form v-model="valid" ref="registra">
            <v-card-text>
              <v-flex sm12>
                <v-text-field
                  v-model="codice"
                  label="Codice Cliente"
                  readonly
                />
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  v-model="email1"
                  label="E-mail"
                  :rules="[(v) => !!v || 'E-mail obbligatoria']"
                />
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  v-model="email2"
                  label="Conferma E-mail"
                  :rules="[(v) => !!v || 'E-mail obbligatoria']"
                />
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  v-model="nome"
                  label="Nome"
                  maxlength="50"
                  :rules="[(v) => !!v || 'Nome obbligatorio']"
                />
              </v-flex>
              <v-flex sm12>
                <v-text-field
                  v-model="cognome"
                  label="Cognome"
                  maxlength="50"
                  :rules="[(v) => !!v || 'Cognome obbligatorio']"
                />
              </v-flex>
              <v-flex sm12>
                <v-text-field v-model="ruolo" label="Ruolo" maxlength="50" />
              </v-flex>
            </v-card-text>
            <v-card-actions>
              <v-btn
                large
                color="#A0A4A7"
                style="color: white"
                @click="clear"
                depressed
                >{{ $t("key331") }}</v-btn
              >
              <v-btn
                large
                style="box-shadow: none; color: white"
                color="primary"
                @click="normalizza"
                :disabled="!valid"
                >{{ $t("key354") }}</v-btn
              >
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      mailDupe: "",
      formTitle: "Errore!",
      redirect: false,
      errorDialog: false,
      errore: "",
      piva: "",
      codice: "",
      email1: "",
      email2: "",
      nome: "",
      cognome: "",
      ruolo: "",
      valid: false,
    };
  },
  mounted() {
    this.codice = this.$route.params.codCliente;
    this.mailDupe = this.$route.params.email;
  },
  methods: {
    Abilita() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/AbilitaCodici", {
          codice: this.codice,
          mail: this.mailDupe,
        })
        .then((res) => {
          if (res.data == "ok") {
            this.formTitle = "OK";
            this.errore = "Utente abilitato correttamente";
            this.redirect = true;
            this.errorDialog = true;
          } else {
            this.formTitle = "Errore!";
            this.errore = "Servizi web non raggiungibili.";
            this.redirect = true;
            this.errorDialog = true;
          }
        });
    },
    isInteger(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    clear() {
      this.$refs.registra.reset();
      this.$refs.registra.resetValidation();
    },
    normalizza() {
      if (this.codice == null || this.codice == undefined) this.codice = "";
      if (this.piva == null || this.codice == undefined) this.piva = "";
      if (this.email1 == null || this.email1 == undefined) this.email1 = "";
      if (this.email2 == null || this.email2 == undefined) this.email2 = "";
      if (this.nome == null || this.nome == undefined) this.nome = "";
      if (this.cognome == null || this.cognome == undefined) this.cognome = "";
      if (this.ruolo == null || this.ruolo == undefined) this.ruolo = "";
      var padCodice = "000000";
      var cod =
        padCodice.substring(0, padCodice.length - this.codice.length) +
        this.codice;
      this.codice = cod;
      this.checkCampi();
    },
    checkCampi() {
      this.errore = "";
      var regexEmail =
        /^(([^\'<>()\[\]\\.,;:\s@"]+(\.[^\'<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
      var resultMail1 = this.email1.trim().match(regexEmail);
      var resultMail2 = this.email2.trim().match(regexEmail);

      if (resultMail1 == null || resultMail2 == null)
        this.errore += " - Non hai inserito una mail valida <br>";

      if (this.email1.trim() != this.email2.trim())
        this.errore += " - Le email inserite non corrispondono <br>";

      if (this.codice == "" || this.codice.length < 6)
        this.errore += " - Inserire un codice cliente valido <br>";

      if (this.email1.trim() == "" || this.email2.trim() == "")
        this.errore += "- Inserire una email valida <br>";

      if (this.nome.trim() == "")
        this.errore += "- Inserire un nome valido <br>";

      if (this.cognome.trim() == "")
        this.errore += "- Inserire un cognome valido <br>";

      if (this.errore != "") this.errorDialog = true;
      else {
        this.InviaForm();
      }
    },
    InviaForm() {
      this.axios
        .post(this.$store.state.servicePath + "/api/Login/RegistrazioneBreve", {
          formTitle: "Errore",
          codice: this.codice,
          email1: this.email1,
          email2: this.email2,
          nome: this.nome,
          cognome: this.cognome,
          ruolo: this.ruolo,
        })
        .then((res) => {
          if (res.data != "") {
            this.formTitle = "Errore!";
            this.errore = res.data;
            this.errorDialog = true;
          } else {
            this.errore =
              "Grazie per esserti registrato.<br>Abbiamo inviato all’indirizzo indicato le credenziali per l’accesso. <br>Ti invitiamo a controllare la tua casella di posta e a perfezionare l’iscrizione.<br>Grazie";
            this.formTitle = "OK";
            this.redirect = true;
            this.errorDialog = true;
          }
        })
        .catch((err) => {
          this.formTitle = "Errore!";
          this.errore = "Servizi web non raggiungibili.";
          this.errorDialog = true;
        });
    },
  },

  watch: {},
};
</script>